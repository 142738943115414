import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

import slider from "./slider/SliderSlice";
import Productslice from "./products/productSlice";
import homeSlicer from "./home/homeSlicer";
import aboutSlicer from "./about/aboutSlicer";
import contactSlice from "./contact/contactSlice";
import ModalSlicer from "./modal/ModalSlicer";
import LanSlicer from "./language/lanSlicer";
import Offers from "./totaloffer/totaloffSlice";
import locationSlice from "./locations/locationSlice";
import authSlice from "./auth/authSlice";
import cartSlice from "./cart/cartSlice";
import checkslice from "./checkout/checkslice";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["authSlice", "cartSlice", "slider"],
};

const rootReducer = combineReducers({
  slider,
  Offers,
  checkslice,
  location: locationSlice,
  LanSlicer,
  ModalSlicer,
  Productslice,
  homeSlicer,
  cartSlice,
  aboutSlicer,
  contactSlice,
  authSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

const persistor = persistStore(store);

export { store, persistor };
