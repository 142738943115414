import { Fragment} from "react";
import {  useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Card from "../../component/Card";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

const TopRated = () => {
  const { t } = useTranslation();
  const { arrivals, loading } = useSelector((state) => state.homeSlicer);

  return (
   
      loading === "idle" && (
        <section className="w-100 container-indent container-fluid arrivals py-4 dir">
          <div className="tt-block-title py-4">
            <h1 className="tt-title">{t("top rated items")}</h1>
            <div className="tt-description">{t("top view in this week")}</div>
          </div>
          <Swiper
            spaceBetween={10}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              0: {
                slidesPerView: 2,
                spaceBetween: 5,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 5,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 5,
              },
            }}
            modules={[Pagination]}
            className="mySwiper"
          >
          
            {arrivals?.map((item, idx) => (
              <SwiperSlide className="custom-padding dir my-md-0" key={idx}>
                <Card item={item} />
              </SwiperSlide>
            ))}
          </Swiper>
        </section>
      )
  );
};

export default TopRated;
