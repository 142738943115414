import { instance } from "../../utils/APi";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const gettotalOffers = createAsyncThunk(
  "Offers/gettotalOffers",
  async (lan, thunkAPI) => {
    const { rejectWithValue,signal } = thunkAPI;
    try {
      const response = await instance.get(`api/offers`, {
        headers: {
          locale: lan,
        },
      },{signal});
      return response.data.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return rejectWithValue(error.message);
    }
  }
);
export const getofferProducts = createAsyncThunk(
  "Offers/getofferProducts",
  async ({ lan, id }, thunkAPI) => {
    const { rejectWithValue,signal } = thunkAPI;
    try {
      const response = await instance.get(`api/offer/products/${id}`, {
        headers: {
          locale: lan,
        },
      },{signal});
      return response.data.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return rejectWithValue(error.message);
    }
  }
);
