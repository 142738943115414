import { instance } from "../../utils/APi";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getbanner = createAsyncThunk(
  "home/getbanner",
  async (_, thunkAPI) => {
    const { rejectWithValue,signal } = thunkAPI;
    try {
      const response = await instance.get(`api/advertise`,{signal});
      return response.data.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return rejectWithValue(error.message);
    }
  }
);

export const getarrivals = createAsyncThunk(
  "home/getarrivals",
  async (lan, thunkAPI) => {
    const { rejectWithValue,signal } = thunkAPI;
    try {
      const response = await instance.get(`api/new-arrival`, {
        headers: {
          'Content-Type': 'application/json',
           locale: lan,
        },
      },{signal});

      return response.data.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return rejectWithValue(error.message);
    }
  }
);

export const getFeatured = createAsyncThunk(
  "home/getFeatured",
  async (lan, thunkAPI) => {
    const { rejectWithValue,signal } = thunkAPI;
    try {
      const response = await instance.get(
        `api/random_categories`,

        {
          headers: {
            locale: lan,
          },
        },{signal}
      );
      return response.data.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return rejectWithValue(error.message);
    }
  }
);

export const getGallery = createAsyncThunk(
  "home/getGallery",
  async (_, thunkAPI) => {
    const { rejectWithValue,signal } = thunkAPI;
    try {
      const response = await instance.get(`api/gallery`,{signal});
      return response.data.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return rejectWithValue(error.message);
    }
  }
);
export const gettopRated = createAsyncThunk(
  "home/gettopRated",
  async (lan, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await instance.get(
        `api/most_visited`,

        {
          headers: {
            locale: lan,
          },
        }
      );
      return response.data.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return rejectWithValue(error.message);
    }
  }
);
