import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { baseUrl } from "../../utils/APi";
import { getGallery } from "./../../store/home/homeAction";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Gallery = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const lan = useSelector((state) => state.LanSlicer.language);
  const { gallery } = useSelector((state) => state.homeSlicer);
  useEffect(() => {
    if (!gallery?.length) {
    const promise=  dispatch(getGallery());
    return () => {
      promise.abort();
    };
    }
  }, [lan, dispatch, gallery?.length]);

  return (
    <div className="container-indent gallery pt-5">
      <div className="container-fluid">
        <div className="row">
          {gallery?.map((item, idx) => (
            <div className="col-6 col-md-4 col-lg-2 gallery-item" key={idx}>
              <LazyLoadImage src={`${baseUrl}${item}`} alt={`slide${idx}`} />
            </div>
          ))}
        </div>

        <div className="tt-block-title my-5">
          <h2 className="tt-title">
            <a target="_blank" href="https://www.instagram.com/walker.stores/">
              @ {t("follow")}
            </a>
          </h2>
          <div className="tt-description">{t("instagram")}</div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
