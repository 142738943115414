import { Fragment, useEffect } from "react";
import { openQuickModal } from "../../store/modal/ModalSlicer";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Loader from "../../component/Loader";
import { baseUrl } from "../../utils/APi";
import { Link, useNavigate } from "react-router-dom";
import { delfromfav, getfav } from "../../store/cart/cartAction";

const Cart = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const lan = useSelector((state) => state.LanSlicer.language);
  const { token, user } = useSelector((state) => state.authSlice);
  const { favlist, loading } = useSelector((state) => state.cartSlice);

  useEffect(() => {
    if (user) {
     const promise = dispatch(getfav({ lan, token }));
     return ()=>{
      promise.abort();
    }
    } else {
      navigate("/login");
    }
  }, [dispatch, lan, token, navigate, user]);

  const handleDelete = (item) => {
      dispatch(delfromfav({ id: item?.id, token })).then(() => {
      dispatch(getfav({ lan, token }));
    });
  };

  return (
    loading === "idle" ? (
        <Fragment>
          <div className="tt-breadcrumb">
            <div className="container">
              <ul>
                <li>
                  <Link to={"/"}>{t("home")}</Link>
                </li>
                <li>/</li>
                <li>{t("wish list")}</li>
              </ul>
            </div>
          </div>
          <div className="container wishbanner py-5">
            <h1 className="tt-title-subpages noborder">{t("wishlist")}</h1>
          </div>
          <div className="container-indent py-5 ">
            <div className="container wishtable p-4">
              {favlist?.length ? (
                <div className="tt-wishlist-box" id="js-wishlist-removeitem">
                  <div className="tt-wishlist-list">
                    {favlist?.map((item, idx) => (
                      <div className="tt-item" key={item?.id}>
                        <div className="tt-col-description">
                          <div className="tt-img">
                            <img
                              src={`${baseUrl}${item?.image}`}
                              alt={item?.name}
                            />
                          </div>
                          <div className="tt-description">
                            <h2 className="tt-title">
                              <span>{item?.name}</span>
                            </h2>
                            <div className="tt-price">
                              {item?.discount_price ? (
                                <span className="old-price">
                                  {item?.price}
                                  <span className="mx-1">{item?.currency}</span>
                                </span>
                              ) : (
                                <span className="new-price">
                                  <span className="old-price"></span>
                                </span>
                              )}
                            </div>

                            <div className="tt-price">
                              <span className="new-price">
                                {item?.discount_price &&
                                item?.discount_price !== 0
                                  ? item?.discount_price
                                  : item?.price}{" "}
                                <span className="mx-1">{item?.currency}</span>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="tt-col-btn">
                          <button
                            className="tt-btn-addtocart main-btn"
                            onClick={() => dispatch(openQuickModal(item?.id))}
                          >
                            <i className="icon-f-39"></i>
                            {t("add to cart")}
                          </button>
                          <Link
                            to={`/products/${item?.id}`}
                            className="btn-link"
                          >
                            <i className="icon-f-73"></i>
                            {t("see product")}
                          </Link>
                          <button
                            className="btn-link js-removeitem"
                            onClick={() => handleDelete(item)}
                          >
                            <i className="icon-h-02"></i>
                            {t("remove")}
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="tt-empty-cart">
                  <span className="tt-icon icon-f-39"></span>
                  <h1 className="tt-title">{t("wish List IS EMPTY")}</h1>
                  <p>{t("You have no items in your wish List.")}</p>
                  <Link to={"/"} className="btn">
                    {t("CONTINUE SHOPPING")}
                  </Link>
                </div>
              )}
            </div>
          </div>
        </Fragment>
      ) : (
        <Loader />
      )
  );
};

export default Cart;
