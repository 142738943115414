import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

export const AuthGuard = ({ children }) => {
  const { user } = useSelector((state) => state.authSlice);
  if (!user) {
    return <Navigate to="/login" />;
  }
  return <>{children}</>;
};
