import { instance } from "../../utils/APi";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getSlides = createAsyncThunk(
  "slides/getSlides",
  async (_, thunkAPI) => {
    const { rejectWithValue, signal } = thunkAPI;
    try {
      const response = await instance.get(`api/slider`, {
        Headers: {
             'Content-Type': 'application/json',
        },
      }, { signal });
      return response.data.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return rejectWithValue(error.message);
    }
  }
);
export const gettopOffer = createAsyncThunk(
  "slides/gettopOffer",
  async (lan, thunkAPI) => {
    const { rejectWithValue, signal  } = thunkAPI;
    try {
      const response = await instance.get(`api/header-announcement`, {
        Headers: {
             'Content-Type': 'application/json',
          locale: lan,
        },
      }, { signal });
      return response.data.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return rejectWithValue(error.message);
    }
  }
);
