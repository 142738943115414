import { Fragment, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import {  passwordConfirmationValidationSchema} from "../../utils/Valid";
import { Link, useNavigate } from "react-router-dom";
import { userRegister } from "./../../store/auth/authAction";
import { sucess, failed } from "../../component/toast/Toast";
import {
  getGovernment,
  getcity,
  getzone,
} from "../../store/locations/locationActions";

const Register = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedGovernment, setSelectedGovernment] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedZone, setSelectedzone] = useState("");
  const { government, city, zone } = useSelector((state) => state.location);
  const lan = useSelector((state) => state.LanSlicer.language);

  const formik = useFormik({
    initialValues: {
      first_name: "",
      email: "",
      password: "",
      phone: "",
      government_id: "",
      city_id: "",
      zone_id: "",
      address: "",
      lng: "455454",
      lat: "4545",
    },
    validationSchema: passwordConfirmationValidationSchema,
    validateOnChange: true,
    onSubmit: (values) => {
      if (values.password !== values.password_confirmation) {
        formik.setFieldError("password_confirmation", "Passwords must match");
        return;
      }
      dispatch(userRegister(values)).then((result) => {
        if (result.payload.status === "error") {
        
          failed(`${result.payload.message}`);
        } else {
          sucess(t("Register has been done"));
          navigate("/");
        }
      });
    },
  });

  useEffect(() => {
    dispatch(getGovernment(lan));
  }, [lan, dispatch]);

  const handleGovernmentChange = (event) => {
    const selectedGovernmentId = event.target.value;
    setSelectedGovernment(selectedGovernmentId);
    dispatch(getcity({ lan, id: selectedGovernmentId }));
    setSelectedCity("");
    setSelectedzone("");
    formik.setValues({
      ...formik.values,
      government_id: selectedGovernmentId,
      city_id: "",
      zone_id: "",
    });
  };

  const handleCityChange = (event) => {
    const selectedCityId = event.target.value;
    setSelectedCity(selectedCityId);
    dispatch(getzone({ lan, id: selectedCityId }));
    setSelectedzone("");

    formik.setValues({
      ...formik.values,
      city_id: selectedCityId,
      zone_id: "",
    });
  };
  const handleZoneChange = (event) => {
    const selectedZoneId = event.target.value;
    setSelectedzone(selectedZoneId);
    formik.setValues({
      ...formik.values,
      zone_id: selectedZoneId,
    });
  };

  return (
    <Fragment>
      <div className="tt-breadcrumb">
        <div className="container">
          <ul>
            <li>
            <Link to={"/"}>{t("home")}</Link>
            </li>
            <li>/</li>
            <li>{t("create an account")}</li>
          </ul>
        </div>
      </div>
      <div className="container-indent py-5">
        <div className="container py-5">
          <h1 className="tt-title-subpages noborder">
            {t("create an account")}
          </h1>
          <div className="tt-login-form">
            <div className="row justify-content-center">
              <div className="col-md-8 col-lg-8">
                <div className="tt-item">
                  <h2 className="tt-title">{t("personal information")}</h2>
                  <div className="form-default">
                    <form id="contactform" onSubmit={formik.handleSubmit}>
                      <div className="form-group">
                        <label htmlFor="first_name">{t("FIRST NAME")} *</label>
                        <div className="tt-required">
                          * {t("Required Fields")}
                        </div>
                        <input
                          className="form-control"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.first_name}
                          name="first_name"
                          id="first_name"
                          required="required"
                          placeholder={t("enter your first name")}
                          wfd-id="id3"
                        ></input>
                      </div>

                      <div className="form-group">
                        <label htmlFor="email">{t("e-mail")} *</label>
                        <input
                          type="email"
                          className="form-control "
                          id="email"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                          placeholder={t("enter your e-mail")}
                          name="email"
                          wfd-id="id5"
                        ></input>
                      </div>

                      <div className="form-group">
                        <label htmlFor="phone">{t("phone")} *</label>
                        <input
                          type="text"
                          min="0"
                          className="form-control"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.phone}
                          id="phone"
                          name="phone"
                          placeholder={t("enter your phone number")}
                          wfd-id="id6"
                        ></input>
                      </div>

                      <div className="form-group">
                        <label htmlFor="government_id">{t("government")}</label>
                        <select
                          id="government_id"
                          name="government_id"
                          title="Government"
                          className="form-control"
                          value={selectedGovernment}
                          onChange={handleGovernmentChange}
                        >
                          <option value="">{t("select government")}</option>
                          {government.map((government) => (
                            <option key={government.id} value={government.id}>
                              {government.name}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="form-group">
                        <label htmlFor="city_id">{t("city")}</label>
                        <select
                          id="city_id"
                          name="city_id"
                          title="Email Address"
                          className="form-control"
                          value={selectedCity}
                          onChange={handleCityChange}
                        >
                          <option value="">{t("select city")}</option>
                          {city.map((city) => (
                            <option key={city.id} value={city.id}>
                              {city.name}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="form-group">
                        <label htmlFor="zone_id">{t("zone")}</label>
                        <select
                          id="zone_id"
                          name="zone_id"
                          title="Email Address"
                          className="form-control"
                          value={selectedZone}
                          onChange={handleZoneChange}
                        >
                          <option value="">{t("select zone")}</option>
                          {zone.map((zone) => (
                            <option key={zone.id} value={zone.id}>
                              {zone.name}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="form-group">
                        <label htmlFor="address">{t("address")}</label>
                        <input
                          type="text"
                          name="address"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.address}
                          placeholder={t("enter your address")}
                          maxLength="255"
                          className="form-control"
                          id="address"
                          wfd-id="id7"
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="password" className="form-label">
                          {t("password")} *
                        </label>
                        <input
                          type="password"
                          className="form-control "
                          id="password"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          defaultValue={formik.values.password}
                          name="password"
                          placeholder={t("enter password")}
                          
                          wfd-id="id8"
                        />
                      </div>
                      <div className="form-group">
  <label htmlFor="password_confirmation" className="form-label">
    {t("Confirm Password")}
  </label>
  <input
    type="password"
    className="form-control"
    id="password_confirmation"
    onChange={formik.handleChange}
    onBlur={formik.handleBlur}
    value={formik.values.password_confirmation}
    name="password_confirmation"
    placeholder={t("Confirm Password")}
  />
  {formik.touched.password_confirmation && formik.errors.password_confirmation ? (
    <div className="text-danger my-1">{formik.errors.password_confirmation}</div>
  ) : null}
</div>

                      <div className="row">
                        <div className="col-auto">
                          <div className="form-group">
                            <button className="btn main-btn" type="submit">
                              {t("create")}
                            </button>
                          </div>
                        </div>
                        <div className="col-auto align-self-center">
                          <div className="form-group">
                            <ul className="additional-links">
                              <li>
                                {t("or")}{" "}
                                <Link to={"/"}>{t("return to store")}</Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default Register;
