import { createSlice } from "@reduxjs/toolkit";
import { getSlides, gettopOffer } from "./SliderAction";

const initialState = {
  slider: [],
  topOffer: [],
  loading: "pending",
  error: null,
};
const Slider = createSlice({
  name: "slides",
  initialState: initialState,
  reducers: {
    cleanUpSlider: (state) => {
      state.slider = [];
    },
    cleanUpSliderStatus: (state) => {
      state.error = null;
      state.loading = "pending";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSlides.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(getSlides.fulfilled, (state, action) => {
        state.slider = action.payload;
        state.loading = "idle";
        state.error = null;
      })
      .addCase(getSlides.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload;
      })
      .addCase(gettopOffer.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(gettopOffer.fulfilled, (state, action) => {
        state.topOffer = action.payload;
        state.loading = "idle";
        state.error = null;
      })
      .addCase(gettopOffer.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload;
      });
  },
});
export const { cleanUpSlider, cleanUpSliderStatus } = Slider.actions;

export default Slider.reducer;
