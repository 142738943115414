import { Link } from "react-router-dom";
const BackTop = () => {
  return (
    <Link
      to={"https://www.facebook.com/walkerstores.eg/"}
      target="_blank"
      className="back-to-top"
    >
      <i className="fa-brands fa-facebook-messenger text"></i>
    </Link>
  );
};

export default BackTop;
