import { createSlice } from "@reduxjs/toolkit";
import {
  getProducts,
  getcategory,
  getsubCategories,
  getsingleProduct,
  searchProduct,
  addReview,
} from "./productAction";

const initialState = {
  products: [],
  category: [],
  subCategory: [],
  schProduct: [],
  review: [],
  singleProduct: null,
  loading: "pending",
  error: null,
};

const Productslice = createSlice({
  name: "products",
  initialState: initialState,
  reducers: {
    clearSearchResults: (state, action) => {
      state.schProduct = null;
    },
    clearproduct: (state, action) => {
      state.singleProduct = null;
    },
    clearproductstatus: (state, action) => {
      state.loading = "pending";
      state.error = null;
    },

    clearsubCatitems: (state, action) => {
      state.subCategory = null;
    },
    clearsubCatstatus: (state, action) => {
      state.loading = "pending";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProducts.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        state.products = action.payload.data;
        state.loading = "idle";
        state.error = null;
      })
      .addCase(getsubCategories.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(getsubCategories.fulfilled, (state, action) => {
        state.subCategory = action.payload;
        state.loading = "idle";
        state.error = null;
      })
      .addCase(getsubCategories.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload;
      })
      .addCase(addReview.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(addReview.fulfilled, (state, action) => {
        state.review = action.payload;
        state.loading = "idle";
        state.error = null;
      })
      .addCase(addReview.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload;
      })
      .addCase(getcategory.fulfilled, (state, action) => {
        state.category = action.payload;
        state.loading = "idle";
        state.error = null;
      })
      .addCase(getsingleProduct.pending, (state, action) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(getsingleProduct.fulfilled, (state, action) => {
        state.singleProduct = action.payload;
        state.loading = "idle";
        state.error = null;
      })
      .addCase(getsingleProduct.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload;
      })
      .addCase(searchProduct.fulfilled, (state, action) => {
        state.schProduct = action.payload;
        state.loading = "idle";
        state.error = null;
      })
      .addCase(getProducts.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload;
      });
  },
});

export const {
  clearSearchResults,
  clearproduct,
  clearproductstatus,
  clearsubCatitems,
  clearsubCatstatus,
} = Productslice.actions;
export default Productslice.reducer;
