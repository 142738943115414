import { Fragment } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { baseUrl } from "../../utils/APi";
import { useTranslation } from "react-i18next";

const BannerBottom = () => {
  const { category } = useSelector((state) => state.Productslice);
  const { t } = useTranslation();
  return (
      category &&  (
        <div className="nomargin container-indent px-lg-5 py-lg-5 ">
          <div className="container-fluid-custom">
            <div className="row">
              {category?.slice(0, 2)?.map((item, idx) => (
                <Link
                  to={`/Category/${item?.id}`}
                  className="col-md-6 px-4 mb-2 mb-lg-0 banner-bottom"
                  key={item?.id}
                >
                  <div className="tt-promo-box tt-one-child hover-type-4 tt-promo-layout02 tp-banner-container">
                    <img src={`${baseUrl}${item?.image}`} alt={`slide${idx}`} />
                    <button className="btn main-btn">{t("Shop Now")} </button>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      )
  );
};

export default BannerBottom;
