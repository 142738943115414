import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import img1 from "../../assets/logo/icons8-united-kingdom-48.png";
import img2 from "../../assets/logo/icons8-egypt-48.png";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../../store/language/lanSlicer";
import { baseUrl } from "../../utils/APi";
import { getSocialMedia } from "../../store/contact/contactAction";
const languages = [
  {
    code: "en",
    name: "English",
    dir: "ltr",
    image: img2,
  },
  {
    code: "ar",
    name: "العربية",
    dir: "rtl",
    image: img1,
  },
];
const SideNav = ({ width, Close, logo, category ,lan}) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const currentLanguageCode = Cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const changeLanguage = (languageCode) => {
    i18n.changeLanguage(languageCode);
    Cookies.set("i18next", languageCode);
  };
  const [show, setShow] = useState(false);
  const toggleLanguage = () => {
    const newLanguageCode = currentLanguage.code === "en" ? "ar" : "en";
    document.body.dir = newLanguageCode === "ar" ? "rtl" : "ltr";
    changeLanguage(newLanguageCode);
    dispatch(setLanguage(newLanguageCode));
  };
  const { SocialMedia } = useSelector((state) => state.contactSlice);

  useEffect(() => {
      dispatch(getSocialMedia());
  }, [dispatch]);
  const sideRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (sideRef.current && !sideRef.current.contains(event.target)) {
        Close();
      }
    }
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [Close]);
  return (

     
    <div
      id="mySidenav"
      className="sidenav"
      style={{ width: width + "px" }}
      ref={sideRef}
    >
      <div className="main-side">
        <div aria-label="close-btn" className="closebtn" onClick={Close}>
          &times;
        </div>
        <Link
          to={"/"}
          aria-label="nav-logo"
          className="my-2 pl-4"
          onClick={Close}
        >
          {logo && (
            <img
              src={`${baseUrl}${logo}`}
              alt="main-logo"
              className="img-fluid sideLogo"
              style={{ width: "120px", height: "auto" }}
            />
          )}
        </Link>
        <div className="main-ul">
        <div className="item mt-5 mt-md-0 ">
          <a  className="nav-link sub-btn"  onClick={() => setShow(!show)}>
            {t("categories")}
            <i
              className={`mx-4 fas fa-angle-right dropdown ${
                show ? "rotate" : "rotate-bottom"
              }`}
            ></i>
          </a>

          <div className={`sub-menu ${show ? "dropShow" : "dropHide"}`}>
          <Link className="nav-link" to={"/totaloffers"} onClick={Close}>
                          {t("offers")}
                        </Link>
            {category?.slice(0, 5).map((item, idx) => (
              <Link className="nav-link" to={`/Category/${item?.id}`} key={idx} onClick={Close}>
                {item?.name}
              </Link>
            ))}
          </div>
        </div>

        <Link className="nav-link" onClick={Close} to={"/about"}>
          {t("about us")}
        </Link>
        <Link className="nav-link" onClick={Close} to={"/jobs"}>
          {t("jobs")}
        </Link>
        <Link className="nav-link" onClick={Close} to={"/branch"}>
          {t("branches")}
        </Link>
        <Link className="nav-link" onClick={Close} to={"/contact"}>
          {t("contact us")}
        </Link>
        </div>

        <ul className={`d-flex sideICons p-0 ${lan === "ar" ? "side-icon-rtl" : "side-icon-ltr"}`}>
          <li>
            {SocialMedia?.data?.map((item) => {
              if (item?.name === "الفيس بوك") {
                return (
                  <a
                    key={item.id}
                    aria-label="facebook"
                    target="_blank"
                    rel="noreferrer"
                    href={item.link}
                  >
                    <i className="fab fa-facebook-f"></i>
                  </a>
                );
              }
              return null;
            })}
          </li>
          <li>
            {SocialMedia?.data?.map((item) => {
              if (item?.name === "انستجرام") {
                return (
                  <a
                    key={item.id}
                    aria-label="facebook"
                    target="_blank"
                    rel="noreferrer"
                    href={item.link}
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                );
              }
              return null;
            })}
          </li>
          <li>
            {SocialMedia?.data?.map((item) => {
              if (item?.name === "الفيس بوك") {
                return (
                  <a
                    key={item.id}
                    aria-label="facebook"
                    target="_blank"
                    rel="noreferrer"
                    href={item.link}
                  >
                    <i className="fa-brands fa-facebook-messenger"></i>
                  </a>
                );
              }
              return null;
            })}
          </li>
          <button className="language" onClick={toggleLanguage}>
            <span className="box d-flex align-items-center">
              <img
                className="w-50 mx-1"
                src={currentLanguage.image}
                alt="language"
              />{" "}
              {t("ar")}
            </span>
          </button>
        </ul>
      </div>
    </div>
  );
};

export default SideNav;
