import { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getAboutSite, getAboutPhoto } from "../../store/about/aboutAction";
import { baseUrl } from "../../utils/APi";
import Loader from "../../component/Loader";
import { Link } from "react-router-dom";
const About = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const lan = useSelector((state) => state.LanSlicer.language);
  const { aboutSite, aboutPhoto, loading } = useSelector(
    (state) => state.aboutSlicer
  );
  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };
  const aboutSiteText = stripHtmlTags(aboutSite ? aboutSite :"");
  useEffect(() => {
    dispatch(getAboutSite(lan));
    dispatch(getAboutPhoto());
  }, [dispatch, lan]);

  return loading === "pending" ? (
    <Loader />
  ) : (
    <Fragment>
      <div className="tt-breadcrumb">
        <div className="container">
          <ul>
            <li>
            <Link to={"/"}>{t("home")}</Link>
            </li>
            <li>/</li>
            <li>{t("about us")}</li>
          </ul>
        </div>
      </div>
      <div className="about my-5">
        <div className="container-indent">
          <div className="container container-fluid-custom-mobile-padding">
            <div className="row">
              <div className="col-xs-12 col-md-11 col-lg-11">
                <div className="tt-about-box-02 mt-md-4">
                  <h1 className="tt-title">{t("WALKER CLOTHING")}</h1>
                  <p className="description py-4">{aboutSiteText && (aboutSiteText)}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-indent">
          <div className="container-fluid-custom">
            <div className="tt-col-img">
              <div className="row">
                <div className="col-sm-12 col-md-9 mx-auto">
                  {aboutPhoto && (
                    <img src={`${baseUrl}${aboutPhoto}`} alt="aboutimage" />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default About;
