import { createSlice } from "@reduxjs/toolkit";
import {
  getbanner,
  getarrivals,
  getFeatured,
  getGallery,
  gettopRated,
} from "./homeAction";

const initialState = {
  banner: [],
  arrivals: [],
  featured: [],
  gallery: [],
  rated: [],
  loading: "pending",
  error: null,
};

const homeSlicer = createSlice({
  name: "home",
  initialState: initialState,
  reducers: {
    clearArrivalsResults: (state) => {
      state.arrivals = [];
    },
    clearArrivalsstatus: (state) => {
      state.error = null;
      state.loading = "pending";
    },
    clearratedResults: (state) => {
      state.rated = [];
    },
    clearratedstatus: (state) => {
      state.error = null;
      state.loading = "pending";
    },
    clearfeaturedResults: (state) => {
      state.featured = [];
    },
    clearfeaturedstatus: (state) => {
      state.error = null;
      state.loading = "pending";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getbanner.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(getbanner.fulfilled, (state, action) => {
        state.banner = action.payload;
        state.loading = "idle";
        state.error = null;
      })
      .addCase(getbanner.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload;
      })
      .addCase(getarrivals.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(getarrivals.fulfilled, (state, action) => {
        state.loading = "idle";
        state.arrivals = action.payload;
        state.error = null;
      })
      .addCase(getarrivals.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload;
      })
      .addCase(getFeatured.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(getFeatured.fulfilled, (state, action) => {
        state.featured = action.payload.slice(0, 4);
        state.error = null;
        state.loading = "idle";
      })
      .addCase(getFeatured.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload;
      })
      .addCase(getGallery.fulfilled, (state, action) => {
        state.gallery = action.payload;
        state.loading = "idle";
        state.error = null;
      })
      .addCase(gettopRated.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(gettopRated.fulfilled, (state, action) => {
        state.rated = action.payload;
        state.loading = "idle";
        state.error = null;
      })
      .addCase(gettopRated.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload;
      });
  },
});
export const {
  clearArrivalsResults,
  clearArrivalsstatus,
  clearratedResults,
  clearratedstatus,
  clearfeaturedResults,
  clearfeaturedstatus,
} = homeSlicer.actions;

export default homeSlicer.reducer;
