import { createSlice } from "@reduxjs/toolkit";

export const LanSlicer = createSlice({
  name: "language",
  initialState: {
    language: "en", // Initial language
  },
  reducers: {
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
  },
});

export const { setLanguage } = LanSlicer.actions;
export default LanSlicer.reducer;
