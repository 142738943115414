// SliderAction.js

import { createAsyncThunk } from "@reduxjs/toolkit";
import { instance } from "../../utils/APi";

export const getAboutSite = createAsyncThunk(
  "about/getAboutSite",
  async (lan, thunkAPI) => {
    const { rejectWithValue, signal } = thunkAPI;
    try {
      const response = await instance.get(`api/about`, {
        headers: {
          locale: lan,
        },
      },   { signal });
      return response.data.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return rejectWithValue(error.message);
    }
  }
);
export const getAboutPhoto = createAsyncThunk(
  "about/getAboutPhoto",
  async (_, thunkAPI) => {
    const { rejectWithValue, signal } = thunkAPI;
    try {
      const response = await instance.get(`api/about-photo`,   { signal });
      return response.data.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return rejectWithValue(error.message);
    }
  }
);
export const getPolicy = createAsyncThunk(
  "about/getPolicy",
  async (lan, thunkAPI) => {
    const { rejectWithValue, signal } = thunkAPI;
    try {
      const response = await instance.get(`api/policy`, {
        headers: {
          locale: lan,
        },
      },   { signal });
      return response.data.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return rejectWithValue(error.message);
    }
  }
);
export const getBranches = createAsyncThunk(
  "about/getBranches",
  async (lan, thunkAPI) => {
    const { rejectWithValue, signal } = thunkAPI;
    try {
      const response = await instance.get(`api/branches`, {
        headers: {
          locale: lan,
        },
        
      },   { signal });
      return response.data.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return rejectWithValue(error.message);
    }
  }
);
