import * as Yup from "yup";

//registration Schema
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  age: Yup.string().required("age is required"),
  Perfered_Contact: Yup.string().required("Perfered_Contact is required"),
  Services: Yup.string().required("must choose your Services"),
  Services_type: Yup.string().required("must choose a Services_type "),
  message: Yup.string().required("message can not be empty"),
  phone: Yup.string()
    .matches(phoneRegExp, "Invalid phone number")
    .required("Phone number is required"),
});

export const passwordConfirmationValidationSchema = Yup.object().shape({
  password_confirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Password confirmation is required"),
});


export const loginSchema = Yup.object().shape({
  phone: Yup.string()
    .matches(phoneRegExp, "Invalid phone number")
    .required("Phone number is required"),
  password: Yup.string().min(3, "Password must be 8 characters long"),
});
