import { useDispatch, useSelector } from "react-redux";
import {  useEffect } from "react";
import { getFeatured } from "../../store/home/homeAction";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { baseUrl } from "../../utils/APi";
import { LazyLoadImage } from "react-lazy-load-image-component";
const Featured = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { featured, loading } = useSelector((state) => state.homeSlicer);
  const lan = useSelector((state) => state.LanSlicer.language);

  useEffect(() => {
    const promise = dispatch(getFeatured(lan));
    return () => {
      promise.abort();
    };
  }, [lan, dispatch]);

  return (
     loading === "idle" && (
        <section className="featured-section py-5">
          <div className="tt-block-title py-4">
            <h1 className="tt-title">{t("featured")}</h1>
            <div className="tt-description">{t("top rated categories")}</div>
          </div>
          <div className="container mt-4">
            <div className="row">
              {featured?.map((item, idx) => (
                <div className="col-6 col-md-6 col-lg-3 cate-item" key={item?.id}>
                  <Link to={`/mainshop/${item?.id}`}>
                    <LazyLoadImage
                      src={`${baseUrl}${item?.image}`}
                      alt={`${item?.name}`}
                      className="cate-img"
                    />
                  </Link>
                  <h5 className="title text-center">{item?.name}</h5>
                  <Link to={`/mainshop/${item?.id}`}>
                    <button className="btn btn-white main-btn">
                      {t("shop now")}
                    </button>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </section>
    
  ));
};
export default Featured;
