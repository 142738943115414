import { useEffect, useState, useMemo, useCallback, useRef } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { searchProduct } from "../../store/products/productAction";
import { baseUrl } from "../../utils/APi";
import { clearSearchResults } from "../../store/products/productSlice";

const Search = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { schProduct } = useSelector((state) => state.Productslice);
  const lan = useSelector((state) => state.LanSlicer.language);
  const [searchVisible, setSearchVisible] = useState(false);
  const [name, setName] = useState("");

  const openSearch = () => {
      setSearchVisible(true);
  };
  const closeSearch = useCallback(() => {
    if (searchVisible) {
      setSearchVisible(false);
      dispatch(clearSearchResults());
      setName("");
    }
  }, [dispatch, searchVisible]);

  const handleSearchDebounced = useCallback(
    (searchTerm) => {
      if(searchTerm){
      dispatch(searchProduct({ lan, name: searchTerm }));
    }},
    [dispatch, lan]
  );

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    setName(searchTerm);
    clearTimeout(handleSearch.timeoutId);
    handleSearch.timeoutId = setTimeout(() => {
      handleSearchDebounced(searchTerm);
    }, 100);
  };

  useEffect(() => {
    return () => {
      clearTimeout(handleSearch.timeoutId);
    };
  }, [handleSearch.timeoutId]);

  const showSearchResults = useMemo(() => {
    return searchVisible && name && schProduct && schProduct.length > 0;
  }, [searchVisible, schProduct,name]);

  const seacrhRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (seacrhRef.current && !seacrhRef.current.contains(event.target)) {
        closeSearch();
      }
    }

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [closeSearch]);
  return (
    <div className="tt-desctop-parent-search tt-parent-box">
      <div className="tt-search tt-dropdown-obj">
        <button
          className="tt-dropdown-toggle"
          onClick={(e) => {
            e.stopPropagation();
            openSearch();
          }}
        >
          <i className="fa-solid fa-magnifying-glass"></i>
        </button>
        <div
          className={`tt-dropdown-menu ${searchVisible ? "search-show" : ""}`}
          ref={seacrhRef}
        >
          <div
            className={`container ${searchVisible ? "search-container" : ""}`}
          >
            <form>
              <div className="tt-col">
                <input
                  type="text"
                  onChange={handleSearch}
                  value={name ? name : ""}
                  className="tt-search-input"
                  placeholder={t("search products . . . ")}
                />
              </div>
              <div className="tt-col">
                <span className="tt-btn-close icon-g-80" onClick={closeSearch}>
                  {" "}
                  <i className="fa-solid fa-xmark"></i>
                </span>
              </div>
              <div className="tt-info-text">
                {t("What are you Looking for ?")}
              </div>
              <div
                className="search-results"
                style={{ display: showSearchResults ? "block" : "none" }}
              >
                <ul className="search-list row px-5">
                  {schProduct?.map((item, idx) => (
                    <li key={idx} className="col-lg-3 col-md-6 col-6">
                      <Link
                        to={`/products/${item?.id}`}
                        onClick={() => closeSearch()}
                      >
                        <div className="thumbnail">
                          <img
                            src={`${baseUrl}${item?.image}`}
                            alt={item?.name}
                          />
                        </div>
                        <div className="tt-description">
                          <div className="tt-title">{item?.name}</div>
                          <div className="tt-price">
                            <span className="new-price">{item?.price}</span>
                          </div>
                        </div>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Search;
