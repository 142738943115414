import { createSlice } from "@reduxjs/toolkit";
import {
  userLogin,
  userRegister,
  ResetPassword,
  userInfo,
  getAddress,
  forgetPass,
  UpdateProfile,
  UpdatePhone,
} from "./authAction";

const initialState = {
  info: [],
  form: [],
  user: null,
  token: localStorage.getItem("token") || null,
  address: [],
  loading: "pending",
  error: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    SignOut: (state) => {
      state.token = null;
      state.user = null;
      localStorage.removeItem("token");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userLogin.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        state.user = action.payload;
        state.loading = "idle";
        state.error = null;

        if (action.payload.data && action.payload.data.token) {
          localStorage.setItem("token", action.payload.data.token);
          state.token = action.payload.data.token;
        }
      })
      .addCase(userLogin.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload;
      })
      .addCase(userRegister.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(userRegister.fulfilled, (state, action) => {
        state.user = action.payload;
        state.loading = "idle";
        state.error = null;

        if (action.payload.data && action.payload.data.token) {
          localStorage.setItem("token", action.payload.data.token);
          state.token = action.payload.data.token;
        }
      })
      .addCase(userRegister.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload;
      })
      .addCase(userInfo.fulfilled, (state, action) => {
        state.user = action.payload.data;
        state.loading = "idle";
        state.error = null;
      })
      .addCase(getAddress.pending, (state, action) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(getAddress.fulfilled, (state, action) => {
        state.address = action.payload;
        state.loading = "idle";
        state.error = null;
      })
      .addCase(getAddress.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload;
      })
      .addCase(forgetPass.pending, (state, action) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(forgetPass.fulfilled, (state, action) => {
        state.loading = "idle";
        state.error = null;
      })
      .addCase(forgetPass.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload;
      })

      .addCase(ResetPassword.fulfilled, (state, action) => {
        state.loading = "idle";
        state.error = null;
      });
  },
});

export const { SignOut } = authSlice.actions;
export default authSlice.reducer;
