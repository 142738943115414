import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { gettotalOffers } from "../../store/totaloffer/totaloffAction";
import { Link } from "react-router-dom";
import { baseUrl } from "../../utils/APi";
import { LazyLoadImage } from "react-lazy-load-image-component";
const Offer = () => {
  const dispatch = useDispatch();
  const lan = useSelector((state) => state.LanSlicer.language);
  const { offers } = useSelector((state) => state.Offers);

  useEffect(() => {
    if (offers?.length === 0 ) {
     const promise= dispatch(gettotalOffers(lan));
      return () => {
        promise.abort();
      };
    }
  }, [lan, dispatch,offers?.length]);

  return (
    <section className="mt-4 offer">
      {offers?.slice(0, 1)?.map((item, idx) => (
        <Link to={`/shop/${item?.id}`} key={item?.id}>
          <LazyLoadImage
            className="banner"
            src={`${baseUrl}${item?.image}`}
            alt={`offer${item?.id}`}
          />
        </Link>
      ))}
    </section>
  );
};

export default Offer;
