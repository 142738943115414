import { createSlice } from "@reduxjs/toolkit";
import { addTofav, delfromfav, getfav, getLogo } from "./cartAction";

const initialState = {
  cart: [],
  favlist: localStorage.getItem("favlist") || [],
  logo: null,
  loading: "pending",
  error: null,
};

const cartSlice = createSlice({
  name: "cart",
  initialState: initialState,
  reducers: {
    addingCart: (state, action) => {
      const { cartItem } = action.payload || {};
      if (cartItem) {
        const existingItemIndex = state.cart?.findIndex(
          (item) => item?.productId === cartItem?.productId
        );
        if (existingItemIndex !== -1) {
          state.cart[existingItemIndex].quantity += cartItem.quantity;
        } else {
          state.cart?.push(cartItem);
        }
      }
    },
    removeFromCart: (state, action) => {
      const { productId } = action.payload || {};
      if (productId) {
        state.cart = state.cart.filter((item) => item.productId !== productId);
      }
    },
    removeAllFromCart: (state) => {
      state.cart = [];
    },
    updateQuantity: (state, action) => {
      const { productId, quantity } = action.payload;
      const itemToUpdate = state.cart.find(
        (item) => item.productId === productId
      );
      if (itemToUpdate) {
        itemToUpdate.quantity = quantity;
      }
    },
  
  },
  extraReducers: (builder) => {
    builder
      .addCase(addTofav.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(addTofav.fulfilled, (state, action) => {
        const { id } = action.payload || {};
        if (id) {
          const isAlreadyFavorite = state.favlist.some(
            (item) => item?.id === id
          );
          if (!isAlreadyFavorite) {
            state.favlist.push(id);
          }
        }
        localStorage.setItem("favlist", JSON.stringify(state.favlist));
      })
      .addCase(addTofav.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload;
      })
      .addCase(delfromfav.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(delfromfav.fulfilled, (state, action) => {
        const { id } = action.payload || {};
        if (id) {
          state.favlist = state.favlist.filter((item) => item.id !== id);
        }
        state.loading = "idle";
        state.error = null;
      })

      .addCase(delfromfav.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload;
      })
      .addCase(getfav.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(getfav.fulfilled, (state, action) => {
        state.favlist = action.payload;
        state.loading = "idle";
        state.error = null;
      })
      .addCase(getLogo.fulfilled, (state, action) => {
        state.logo = action.payload;
        state.loading = "idle";
        state.error = null;
      })
      .addCase(getfav.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload;
      });
  },
});

export const {
  addingCart,
  removeFromCart,
  removeAllFromCart,
  updateQuantity,
 
} = cartSlice.actions;

export default cartSlice.reducer;
