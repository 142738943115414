import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { gettopOffer } from "../../store/slider/SliderAction";
import { Swiper, SwiperSlide } from "swiper/react";


const TopHeader = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const lan = useSelector((state) => state.LanSlicer.language);
  const { topOffer, loading } = useSelector((state) => state.slider);
  
  useEffect(() => {
    const promise = dispatch(gettopOffer(lan));
    return () => {
      promise.abort();
    };
  }, [lan, dispatch]);
  return (
      loading === "idle"&& topOffer[0].value > 0 && (
        <section className="w-100 slider-container dir">
          <div className="slider-wrapper w-100">
            <Swiper
              breakpoints={{
                640: {
                  slidesPerView: 3,
                  spaceBetween: 5,
                },
                768: {
                  slidesPerView: 5,
                  spaceBetween: 5,
                },
                1000: {
                  slidesPerView: 5,
                  spaceBetween: 5,
                },
                1200: {
                  slidesPerView: 10,
                  spaceBetween: 5,
                },
              }}
              loop={true}
              className="py-2 w-100 mySwiper"
            >
              {topOffer && (
                <SwiperSlide>
                  <Link
                    to={"/totaloffers"}
                    className="text-uppercase slide-track"
                  >
                    {t("shop now sale")} {topOffer[0]?.value} %
                  </Link>
                </SwiperSlide>
              )}
              {topOffer && (
                <SwiperSlide>
                  <Link
                    to={"/totaloffers"}
                    className="text-uppercase slide-track"
                  >
                    {t("shop now sale")} {topOffer[0]?.value} %
                  </Link>
                </SwiperSlide>
              )}
              {topOffer && (
                <SwiperSlide>
                  <Link
                    to={"/totaloffers"}
                    className="text-uppercase slide-track"
                  >
                    {t("shop now sale")} {topOffer[0]?.value} %
                  </Link>
                </SwiperSlide>
              )}
              {topOffer && (
                <SwiperSlide>
                  <Link
                    to={"/totaloffers"}
                    className="text-uppercase slide-track"
                  >
                    {t("shop now sale")} {topOffer[0]?.value} %
                  </Link>
                </SwiperSlide>
              )}
              {topOffer && (
                <SwiperSlide>
                  <Link
                    to={"/totaloffers"}
                    className="text-uppercase slide-track"
                  >
                    {t("shop now sale")} {topOffer[0]?.value} %
                  </Link>
                </SwiperSlide>
              )}
              {topOffer && (
                <SwiperSlide>
                  <Link
                    to={"/totaloffers"}
                    className="text-uppercase slide-track"
                  >
                    {t("shop now sale")} {topOffer[0]?.value} %
                  </Link>
                </SwiperSlide>
              )}
              {topOffer && (
                <SwiperSlide>
                  <Link
                    to={"/totaloffers"}
                    className="text-uppercase slide-track"
                  >
                    {t("shop now sale")} {topOffer[0]?.value} %
                  </Link>
                </SwiperSlide>
              )}
              {topOffer && (
                <SwiperSlide>
                  <Link
                    to={"/totaloffers"}
                    className="text-uppercase slide-track"
                  >
                    {t("shop now sale")} {topOffer[0]?.value} %
                  </Link>
                </SwiperSlide>
              )}
              {topOffer && (
                <SwiperSlide>
                  <Link
                    to={"/totaloffers"}
                    className="text-uppercase slide-track"
                  >
                    {t("shop now sale")} {topOffer[0]?.value} %
                  </Link>
                </SwiperSlide>
              )}
              {topOffer && (
                <SwiperSlide>
                  <Link
                    to={"/totaloffers"}
                    className="text-uppercase slide-track"
                  >
                    {t("shop now sale")} {topOffer[0]?.value} %
                  </Link>
                </SwiperSlide>
              )}
              {topOffer && (
                <SwiperSlide>
                  <Link
                    to={"/totaloffers"}
                    className="text-uppercase slide-track"
                  >
                    {t("shop now sale")} {topOffer[0]?.value} %
                  </Link>
                </SwiperSlide>
              )}
              {topOffer && (
                <SwiperSlide>
                  <Link
                    to={"/totaloffers"}
                    className="text-uppercase slide-track"
                  >
                    {t("shop now sale")} {topOffer[0]?.value} %
                  </Link>
                </SwiperSlide>
              )}
              {topOffer && (
                <SwiperSlide>
                  <Link
                    to={"/totaloffers"}
                    className="text-uppercase slide-track"
                  >
                    {t("shop now sale")} {topOffer[0]?.value} %
                  </Link>
                </SwiperSlide>
              )}
              {topOffer && (
                <SwiperSlide>
                  <Link
                    to={"/totaloffers"}
                    className="text-uppercase slide-track"
                  >
                    {t("shop now sale")} {topOffer[0]?.value} %
                  </Link>
                </SwiperSlide>
              )}
              {topOffer && (
                <SwiperSlide>
                  <Link
                    to={"/totaloffers"}
                    className="text-uppercase slide-track"
                  >
                    {t("shop now sale")} {topOffer[0]?.value} %
                  </Link>
                </SwiperSlide>
              )}
              {topOffer && (
                <SwiperSlide>
                  <Link
                    to={"/totaloffers"}
                    className="text-uppercase slide-track"
                  >
                    {t("shop now sale")} {topOffer[0]?.value} %
                  </Link>
                </SwiperSlide>
              )}
              {topOffer && (
                <SwiperSlide>
                  <Link
                    to={"/totaloffers"}
                    className="text-uppercase slide-track"
                  >
                    {t("shop now sale")} {topOffer[0]?.value} %
                  </Link>
                </SwiperSlide>
              )}
              {topOffer && (
                <SwiperSlide>
                  <Link
                    to={"/totaloffers"}
                    className="text-uppercase slide-track"
                  >
                    {t("shop now sale")} {topOffer[0]?.value} %
                  </Link>
                </SwiperSlide>
              )}
              {topOffer && (
                <SwiperSlide>
                  <Link
                    to={"/totaloffers"}
                    className="text-uppercase slide-track"
                  >
                    {t("shop now sale")} {topOffer[0]?.value} %
                  </Link>
                </SwiperSlide>
              )}
            </Swiper>
          </div>
        </section>
      )
  );
};

export default TopHeader;
