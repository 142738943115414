import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  getGovernment,
  getcity,
  getzone,
} from "../../store/locations/locationActions";
import { failed, sucess } from "../../component/toast/Toast";
import { useFormik } from "formik";
import { getAddress, setaddress } from "../../store/auth/authAction";

const EditProfile = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedGovernment, setSelectedGovernment] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedZone, setSelectedzone] = useState("");
  const { government, city, zone } = useSelector((state) => state.location);
  const lan = useSelector((state) => state.LanSlicer.language);
  const { token, address } = useSelector((state) => state.authSlice);

  useEffect(() => {
    dispatch(getAddress(token));
  }, [dispatch, token]);

  const formik = useFormik({
    initialValues: {
      government_id: "",
      city_id: "",
      zone_id: "",
      address: "",
    },
    validateOnChange: false,
    onSubmit: async (values) => {
      if (
        values?.government_id?.length > 0 &&
        values?.city_id?.length > 0 &&
        values?.zone_id?.length > 0 &&
        values?.address?.length > 0
      ) {
        try {
          const result = await dispatch(setaddress({ values, token }));
          await dispatch(getAddress(token));
          if (result.payload.status === "error") {
            failed(`${result.payload.message}`);
          } else {
            sucess(t("address have been added successfully"));
          }
        } catch (error) {
          console.error("Error:", error);
        }
      } else {
        failed(t("Please fill in all required fields correctly"));
      }
    },
  });

  useEffect(() => {
    dispatch(getGovernment(lan));
  }, [lan, dispatch]);

  const handleGovernmentChange = (event) => {
    const selectedGovernmentId = event.target.value;
    setSelectedGovernment(selectedGovernmentId);
    dispatch(getcity({ lan, id: selectedGovernmentId }));
    setSelectedCity("");
    setSelectedzone("");
    formik.setValues({
      ...formik.values,
      government_id: selectedGovernmentId,
      city_id: "",
      zone_id: "",
    });
  };

  const handleCityChange = (event) => {
    const selectedCityId = event.target.value;
    setSelectedCity(selectedCityId);
    dispatch(getzone({ lan, id: selectedCityId }));
    setSelectedzone("");
    formik.setValues({
      ...formik.values,
      city_id: selectedCityId,
      zone_id: "",
    });
  };
  const handleZoneChange = (event) => {
    const selectedZoneId = event.target.value;
    setSelectedzone(selectedZoneId);
    formik.setValues({
      ...formik.values,
      zone_id: selectedZoneId,
    });
  };

  return (
    <Fragment>
      <div className="tt-shopping-layout">
        <div className="tt-wrapper">
          <Link to={"/"} className="tt-link-back">
            <i className="icon-f-79"></i> {t("RETURN TO Home PAGE")}
          </Link>
        </div>
        <div className="form-group">
          <label htmlFor="zone_id">{t("My Address")}</label>
          <select
            id="zone_id"
            name="zone_id"
            title="Email Address"
            className="form-control mt-3"
          >
            <option value="">{t("select Address")}</option>
            {address?.map((item, idx) => (
              <option key={idx} value={item?.id}>
                {item?.government} - {item?.city} - {item?.zone} -{" "}
                {item?.address}
              </option>
            ))}
          </select>
        </div>
        <div className="tt-wrapper">
          <h6 className="tt-title">{t("Add A New Address")}</h6>
          <div className="form-default">
            <form onSubmit={formik.handleSubmit}>
              <div className="form-group">
                <label htmlFor="government_id">{t("government")}</label>
                <select
                  id="government_id"
                  name="government_id"
                  title="Government"
                  className="form-control"
                  value={selectedGovernment}
                  onChange={handleGovernmentChange}
                >
                  <option value="">{t("select government")}</option>
                  {government?.map((government) => (
                    <option key={government?.id} value={government?.id}>
                      {government?.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="city_id">{t("city")}</label>
                <select
                  id="city_id"
                  name="city_id"
                  title="Email Address"
                  className="form-control"
                  value={selectedCity}
                  onChange={handleCityChange}
                >
                  <option value="">{t("select city")}</option>
                  {city?.map((city) => (
                    <option key={city?.id} value={city?.id}>
                      {city?.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="zone_id">{t("zone")}</label>
                <select
                  id="zone_id"
                  name="zone_id"
                  title="Email Address"
                  className="form-control"
                  value={selectedZone}
                  onChange={handleZoneChange}
                >
                  <option value="">{t("select zone")}</option>
                  {zone?.map((zone) => (
                    <option key={zone?.id} value={zone?.id}>
                      {zone?.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="address">{t("address")}</label>
                <input
                  type="text"
                  name="address"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.address}
                  maxLength="255"
                  className="form-control"
                  id="address"
                  wfd-id="id7"
                />
              </div>

              <div className="row tt-offset-21">
                <div className="col-auto">
                  <button type="submit" className="btn main-btn">
                    {t("Adding Address")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default EditProfile;
