import { Link, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { baseUrl, instance } from "../../utils/APi";
import { Fragment } from "react";
import {
  addReview,
} from "../../store/products/productAction";
import { addingCart } from "../../store/cart/cartSlice";
import { failed, sucess } from "../../component/toast/Toast";

const SingleProduct = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const lan = useSelector((state) => state.LanSlicer.language);
  const { token } = useSelector((state) => state.authSlice);
  const [singleProduct, setProduct] = useState(null);
  const { productId } = useParams();

  const fetchProduct = async () => {
    try {
      const response = await instance.get(`/api/product/${productId}`, {
        headers: {
          locale: lan,
        },
      });
      setProduct(response?.data?.data);
    } catch (error) {
      console.error("Error fetching product:", error);
    }
  };
  useEffect(() => {
    fetchProduct();
  }, [productId, lan]);
  const totalStars = singleProduct?.reviews?.reduce(
    (acc, review) => acc + review?.stars,
    0
  );
  const averageRating =
    singleProduct?.reviews?.length > 0
      ? totalStars / singleProduct?.reviews?.length
      : 0;

  const [selectedImage, setSelectedImage] = useState();
  const handleImageChange = (newImage) => {
    setSelectedImage(newImage);
  };

  useEffect(() => {
    if (singleProduct) {
      setSelectedImage(singleProduct?.product_photo);
    }
  }, [singleProduct , lan , dispatch]);

  const [selectedColor, setSelectedColor] = useState("");
  const [selectedSize, setSelectedSize] = useState("");
  const [quantity, setQuantity] = useState(1);


  const addTOCart = () => {
    const cartItem = {
      productId: singleProduct?.id,
      productName: singleProduct?.name,
      productPhoto: singleProduct?.product_photo || singleProduct?.image,
      quantity: quantity,
      price:
        singleProduct?.discount_price && singleProduct?.discount_price !== 0
          ? singleProduct?.discount_price
          : singleProduct?.price,
      color: selectedColor,
      size: selectedSize,
    };
    
    dispatch(addingCart({ cartItem }));
    sucess(t("added successfully"));
  };
 
  const [formData, setFormData] = useState({
    product_id: singleProduct?.id,
    stars: 0,
    name: "",
    review: "",
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      product_id: singleProduct?.id,
      [id]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (token) {
      dispatch(addReview({ formData, token }));
      sucess(t("your review have been added succefuuly"));
    } else {
      failed(t("first you must have an account"));
    }
    setFormData({
      product_id: null,
      stars: 0,
      name: "",
      review: "",
    });
  };
  return (
    <Fragment>
      <div className="tt-breadcrumb">
        <div className="container">
          <ul>
            <li>
              <Link to={"/"}>{t("home")}</Link>
            </li>
            <li>/</li>
            <li>
              <Link to={"/"}>{t("shop")}</Link>
            </li>
            <li>/</li>
            <li>{singleProduct?.name}</li>
          </ul>
        </div>
      </div>
      <div id="tt-pageContent">
        <div className="container-indent">
          <div className="container-fluid container-fluid-mobile px-lg-5 ">
            <div className="row">
              <div className="col-6">
                <div className="tt-product-vertical-layout">
                  <div className="tt-product-single-img">
                    <div className="img-card row single-product">
                      <div className="col-md-12 main-img col-12">
                        <img
                          src={`${baseUrl}${selectedImage}`}
                          alt={singleProduct?.name}
                          id="featured-image"
                        />
                      </div>
                      <div className="col-md-12 col-12">
                        <div className="small-Card">
                          <li
                            className={
                              selectedImage === singleProduct?.product_photo
                                ? "active-img"
                                : ""
                            }
                          >
                            <span
                              className="small-Img"
                              onClick={() =>
                                handleImageChange(singleProduct?.product_photo)
                              }
                            >
                              <img
                                src={`${baseUrl}${singleProduct?.product_photo}`}
                                alt={singleProduct?.name}
                              />
                            </span>
                          </li>

                          {singleProduct?.images?.slice(0,3)?.map((item, index) => (
                            <li
                              key={index}
                              className={
                                selectedImage === item?.image
                                  ? "active-img"
                                  : ""
                              }
                            >
                              <span
                                className="small-Img"
                                onClick={() => handleImageChange(item?.image)}
                              >
                                <img
                                  src={`${baseUrl}${item?.image}`}
                                  alt={item?.name}
                                />
                              </span>
                            </li>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6 px-lg-5">
                <div className="tt-product-single-info">
                  <div className="tt-wrapper">
                    <div className="tt-label">
                      <div className="tt-label-new">{t("NEW")}</div>
                      <div className="tt-label-out-stock">
                        {singleProduct?.product_quantity} {t("in Stock")}
                      </div>
                      {singleProduct?.discount_price ? (
                        <div className="tt-label tt-label-sale">
                          {t("Sale")} {singleProduct?.discounts[0]?.discount_value}
                          <span className="mx-1">
                            {" "}
                            {singleProduct?.discounts[0]?.discount_type}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="tt-label tt-label-our-fatured">
                        {singleProduct?.category?.name}
                      </div>
                    </div>
                  </div>
                  <div className="tt-add-info">
                    <ul>
                      <li>
                        <span>{t("product id :")}</span> {singleProduct?.id}
                      </li>
                      <li>
                        <span>{t("Availability :")}</span>{" "}
                        {singleProduct?.product_quantity} {t("in Stock")}
                      </li>
                    </ul>
                  </div>
                  <h1 className="tt-title">{singleProduct?.name}</h1>
                  <div className="tt-price">
                    {singleProduct?.discount_price ? (
                      <span className="old-price">
                        {singleProduct?.price}
                        <span className="mx-1">{singleProduct?.currency}</span>
                      </span>
                    ) : (
                      <span className="new-price">
                        <span className="old-price"></span>
                      </span>
                    )}
                  </div>

                  <div className="tt-price">
                    <span className="new-price">
                      {singleProduct?.discount_price &&
                      singleProduct?.discount_price !== 0
                        ? singleProduct?.discount_price
                        : singleProduct?.price}{" "}
                      <span className="mx-1">{singleProduct?.currency}</span>
                    </span>
                  </div>

                  <div className="tt-review">
                    {" "}
                    <div className="tt-rating">
                      {averageRating === 0 ? (
                        <>
                          <i className="fa-regular fa-star"></i>
                          <i className="fa-regular fa-star"></i>
                          <i className="fa-regular fa-star"></i>
                          <i className="fa-regular fa-star"></i>
                          <i className="fa-regular fa-star"></i>
                        </>
                      ) : (
                        Array.from({ length: Math.ceil(averageRating) }).map(
                          (_, index) => (
                            <i
                              key={index}
                              className="fa-solid fa-star icon-star"
                            ></i>
                          )
                        )
                      )}
                    </div>
                    <span className="cust-review">
                      ({singleProduct?.review} {t("customer review")})
                    </span>
                  </div>
                  <div
                    className="tt-wrapper"
                    dangerouslySetInnerHTML={{ __html: singleProduct?.desc }}
                  />
                  <div className="tt-wrapper">
                    <div className="tt-countdown_box_02"></div>
                  </div>
                  <div className="tt-swatches-container">
                    <div className="tt-wrapper">
                      {singleProduct?.attributes?.length > 0 &&
                        singleProduct?.attributes?.some(
                          (item) =>
                            item?.name?.includes("المقاس") ||
                            item?.name?.toLowerCase().includes("measuring")
                        ) && (
                          <form className="form-default">
                            <div className="tt-title-options">{t("size")}</div>
                            <div className="form-group">
                              <select
                                className="form-control"
                                onChange={(e) =>
                                  setSelectedSize(e.target.value)
                                }
                                value={selectedSize}
                              >
                                {singleProduct?.attributes?.map((item, index) => {
                                  if (
                                    item?.name?.includes("المقاس") ||
                                    item?.name
                                      .toLowerCase()
                                      .includes("measuring")
                                  ) {
                                    return (
                                      <option
                                        key={index}
                                        className="options-size"
                                        value={item?.value}
                                      >
                                        {item?.value}
                                      </option>
                                    );
                                  } else {
                                    return null;
                                  }
                                })}
                              </select>
                            </div>
                          </form>
                        )}
                    </div>
                    <div className="tt-wrapper">
                      {singleProduct?.attributes?.length > 0 &&
                        singleProduct?.attributes?.some(
                          (item) =>
                            item?.name?.includes("اللون") ||
                            item?.name?.toLowerCase().includes("color")
                        ) && (
                          <form className="form-default">
                            <div className="tt-title-options">{t("color")}</div>
                            <div className="form-group">
                              <select
                                className="form-control"
                                onChange={(e) =>
                                  setSelectedColor(e.target.value)
                                }
                                value={selectedColor}
                              >
                                {singleProduct?.attributes?.map(
                                  (item, index) => {
                                    if (
                                      item?.name?.includes("اللون") ||
                                      item?.name?.toLowerCase().includes("color")
                                    ) {
                                      return (
                                        <option
                                          key={index}
                                          className="options-size"
                                          value={item?.value}
                                        >
                                          {item?.value}
                                        </option>
                                      );
                                    } else {
                                      return null;
                                    }
                                  }
                                )}
                              </select>
                            </div>
                          </form>
                        )}
                    </div>
                  </div>
                  <div className="tt-wrapper">
                    <div className="tt-row-custom-01">
                      <div className="col-item">
                        <div className="tt-input-counter style-01">
                          <i
                            className="fa-solid fa-minus minus-btn"
                            onClick={() =>
                              setQuantity((prevQuantity) =>
                                Math.max(prevQuantity - 1, 1)
                              )
                            }
                          ></i>

                          <input
                            type="text"
                            value={quantity}
                            size="5"
                            readOnly
                          />

                          <i
                            className="fa-solid fa-plus plus-btn"
                            onClick={() =>
                              setQuantity((prevQuantity) => prevQuantity + 1)
                            }
                          ></i>
                        </div>
                      </div>
                      <div className="col-item">
                        <button
                          onClick={() => addTOCart()}
                          className="btn btn-lg black-btn"
                        >
                          <i className="icon-f-39"></i>
                          {t("Add To Cart")}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="tt-wrapper">
                    <div className="tt-add-info">
                      <ul>
                        <li>
                          <span>{t("Main Category : ")}</span>{" "}
                          {singleProduct?.category?.name}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="tt-collapse-block">
                    <div className="tt-item"></div>
                    <div className="tt-item">
                    
                      <div className="tt-collapse-content">
                    
                        <div className="tt-item">
                          <div className="tt-collapse-title">
                            {t("PRODUCT REVIEWS")}
                          </div>
                          <div className="tt-collapse-content">
                            <div className="tt-review-block">
                              <div className="tt-row-custom-02">
                                <div className="col-item">
                                  <h2 className="tt-title">
                                    ( {singleProduct?.reviews?.length} ) {t("REVIEW FOR VARIABLE PRODUCT")}
                                  </h2>
                                </div>
                                
                              </div>
                              <div className="tt-review-comments">
                                {singleProduct?.reviews
                                  ?.slice(0, 3)
                                  .map((item, idx) => (
                                    <div className="tt-item" key={idx}>
                                      <div className="tt-avatar">
                                        <span> {item?.name}</span>
                                      </div>
                                      <div className="tt-content">
                                        <div className="tt-rating">
                                          <i className="icon-star"></i>
                                          <i className="icon-star"></i>
                                          <i className="icon-star"></i>
                                          <i className="icon-star-half"></i>
                                          <i className="icon-star-empty"></i>
                                        </div>
                                        <div className="tt-comments-info">
                                          <span className="username">
                                          {t("by :")} <span>{item?.user}</span>
                                          </span>
                                          <span className="time">
                                            {item?.created_at}
                                          </span>
                                        </div>
                                        <div className="tt-comments-title">
                                          {item?.review}
                                        </div>
                                        <p></p>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                              <div className="tt-review-form">
                               <form
                                  className="form-default mt-5"
                                  onSubmit={handleSubmit}
                                >
                                  <div className="form-group">
                                    <label
                                      htmlFor="inputName"
                                      className="control-label"
                                    >
                                     {t("YOUR NAME *")} 
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="name"
                                      placeholder={t("enter your name")}
                                      value={formData.name}
                                      onChange={handleChange}
                                      required
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label
                                      htmlFor="inputRating"
                                      className="control-label"
                                    >
                                       {t("YOUR RATING *")} 
                                      
                                    </label>
                                    <input
                                      type="number"
                                      min="0"
                                      max="5"
                                      className="form-control"
                                      id="stars"
                                      placeholder="Enter your rating (0-5)"
                                      value={formData.stars}
                                      onChange={handleChange}
                                      required
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label
                                      htmlFor="textarea"
                                      className="control-label"
                                    >
                                      {t("YOUR REVIEW *")}
                                      
                                    </label>
                                    <textarea
                                      className="form-control"
                                      id="review"
                                      placeholder={t("enter your review")}
                                      rows="8"
                                      value={formData.review}
                                      onChange={handleChange}
                                      required
                                    ></textarea>
                                  </div>
                                  <div className="form-group">
                                    <button type="submit" className="btn">
                                     {t("Save")}                                     </button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default React.memo(SingleProduct);
