import React from "react";
import { Link } from "react-router-dom";
import Search from "./Search";
import cartimg from "../../assets/logo/icons8-cart-96.png";
import { baseUrl } from "../../utils/APi";
export const MobileHeader = ({ open, openCart, cart, favlist, logo }) => {
  return (
    <div className="tt-mobile-header mt-4">
      <div className="container-fluid">
        <div className="tt-header-row row">
          <div className="tt-col-obj tt-obj-options obj-move-right col-12 d-flex justify-content-between">
            <Search />
            <div className="tt-desctop-parent-cart tt-parent-box">
              <div className="tt-cart tt-dropdown-obj">
                <button
                  className="tt-dropdown-toggle"
                  onClick={(e) => {
                    e.stopPropagation();
                    openCart();
                  }}
                >
                  <img src={cartimg} alt="cart-icon" className="cart-img" />
                  <span className="tt-badge-cart">{cart?.length}</span>
                </button>
              </div>
            </div>
            <div className="tt-desctop-parent-cart tt-parent-box mx-2">
              <div className="tt-cart tt-dropdown-obj">
                <Link to={"/wishlist"} className="tt-dropdown-toggle">
                  <i className="fa-regular fa-heart"></i>
                  <span className="tt-badge-cart">{favlist?.length}</span>
                </Link>
              </div>
            </div>
            <div className="tt-desctop-parent-account tt-parent-box">
              <div className="tt-account tt-dropdown-obj">
                <Link to={"/profile"} className="tt-dropdown-toggle">
                  <i className="fa-regular fa-user"></i>
                </Link>
              </div>
            </div>

            <div className="tt-desctop-parent-multi tt-parent-box">
              <div className="tt-multi-obj tt-dropdown-obj">
                <button
                  className="tt-dropdown-toggle"
                  onClick={(e) => {
                    e.stopPropagation();
                    open();
                  }}
                >
                  <i className="fa-solid fa-bars"></i>
                </button>
              </div>
            </div>
          </div>
          <div className="tt-mobile-parent-menu d-flex px-2 px-md-0 col-12">
            <div className="tt-logo-container justify-content-center ">
              <Link className="tt-logo-alignment main_logo" to={"/"}>
                <img src={`${baseUrl}${logo}`} alt="logo" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid tt-top-line">
        <div className="row"></div>
      </div>
    </div>
  );
};
