import { createSlice } from "@reduxjs/toolkit";
import {
  getContactInfo,
  getSocialMedia,
  formContact,
  getJobs,
  addJob,
  getPayment
} from "./contactAction";

const initialState = {
  info: [],
  SocialMedia: [],
  Jobs: [],
  payments:[],
  loading: "pending",
  error: null,
};

const contactSlice = createSlice({
  name: "contact",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getContactInfo.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(getContactInfo.fulfilled, (state, action) => {
        state.info = action.payload;
        state.loading = "idle";
        state.error = null;
      })
      .addCase(getContactInfo.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload;
      })
      .addCase(getPayment.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(getPayment.fulfilled, (state, action) => {
        state.payments = action.payload;
        state.loading = "idle";
        state.error = null;
      })
      .addCase(getPayment.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload;
      })
      .addCase(addJob.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(addJob.fulfilled, (state) => {
        state.loading = "idle";
        state.error = null;
      })
      .addCase(addJob.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload;
      })
      .addCase(getSocialMedia.fulfilled, (state, action) => {
        state.SocialMedia = action.payload;
        state.loading = "idle";
        state.error = null;
      })
      .addCase(formContact.fulfilled, (state, action) => {
        // state.form = action.payload;
        state.loading = "idle";
        state.error = null;
      })
      .addCase(getJobs.fulfilled, (state, action) => {
        state.jobs = action.payload;
        state.loading = "idle";
        state.error = null;
      });
  },
});

export default contactSlice.reducer;
