import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getJobs } from "../../store/contact/contactAction";
import { baseUrl } from "../../utils/APi";

const Jobs = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const lan = useSelector((state) => state.LanSlicer.language);
  const { jobs } = useSelector((state) => state.contactSlice);

  useEffect(() => {
    const promise = dispatch(getJobs(lan));
    return () => {
      promise.abort();
    };
  }, [dispatch, lan]);

  return (
    <Fragment>
      <div className="tt-breadcrumb">
        <div className="container">
          <ul>
            <li>
              <Link to={"/"}>{t("home")} </Link>
            </li>
            <li>/</li>
            <li> {t("our Jobs")}</li>
          </ul>
        </div>
      </div>
      <div className="container-indent">
        <div className="container container-fluid-custom-mobile-padding my-md-5">
          <h1 className="tt-title-subpages noborder">{t("our Jobs")}</h1>
          <div className="tt-box-thumb-listing">
            <div className="row">
              {jobs?.map((item, idx) => (
                <Link
                  to={"/JobForm"}
                  className="col-sm-6 col-md-6 col-lg-4"
                  key={idx}
                >
                  <div className="tt-box-thumb">
                    <div className="tt-img">
                      <img
                        src={`${baseUrl}${item?.photo}`}
                        alt={`slide${idx}`}
                      />
                    </div>
                    <h6 className="tt-title">
                      <span>{item?.name}</span>
                    </h6>
                    <p>{item?.desc}</p>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default Jobs;
