import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Card from "../../component/Card";
import { getProducts } from "../../store/products/productAction";
import Loader from "../../component/Loader";
import React from "react";
import ReactPaginate from 'react-paginate';
import grid from "../../assets/logo/all.png"
import mini from "../../assets/logo/mini.png"


const MainShop = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const lan = useSelector((state) => state.LanSlicer.language);
  const { products, loading } = useSelector((state) => state.Productslice);
  const { id } = useParams();
  const [upperVal, setUpperVal] = useState(2000);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  const [currentPage, setCurrentPage] = useState(0); 
  const [productsPerPage] = useState(9);
  const [uniqueColors, setUniqueColors] = useState([]);
  const [uniqueSizes, setUniqueSizes] = useState([]);

  useEffect(() => {
    const colors = products?.flatMap((product) => {
      if (product && product?.attributes) {
        return product?.attributes
          .filter(attr => ["Color", "color","colour", "color_name", "اللون"].includes(attr?.name?.toLowerCase()))
          .map(attr => attr?.value);
      }
      return [];
    });
  
    const uniqueColors = [...new Set(colors?.filter((color) => color !== null))];
    setUniqueColors(uniqueColors);
  }, [products]);
  
  const filterProductsByColor = (color) => {
    const filtered = products?.filter((product) => {
      const colorAttributes = product?.attributes?.filter((attr) => {
        const nameVariations = ["Color","color", "colour", "color_name", "اللون"];
        return nameVariations?.some(
          (name) => attr?.name?.toLowerCase() === name?.toLowerCase()
        );
      });
      const matchesColor = colorAttributes?.some(attr => attr?.value?.toLowerCase() === color?.toLowerCase());
      return matchesColor;
    });
    setFilteredProducts(filtered);
    setCurrentPage(0);
  };
 
    const indexOfLastProduct = (currentPage + 1) * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    const currentProducts = filteredProducts?.slice(indexOfFirstProduct, indexOfLastProduct);
   
    const handlePageClick = ({ selected }) => {
      setCurrentPage(selected);
    };

  useEffect(() => {
    const Sizes = products?.flatMap((product) => {
      if (product && product?.attributes) {
        return product?.attributes
          .filter(attr => ["measuring", "المقاس"].includes(attr?.name?.toLowerCase()))
          .map(attr => attr?.value);
      }
      return [];
    });
  
    const uniqueSizes = [...new Set(Sizes?.filter((size) => size !== null))];
    setUniqueSizes(uniqueSizes);
  }, [products]);
  
  const filterProductsBySize = (size) => {
    const filtered = products?.filter((product) => {
      const sizeAttributes = product?.attributes?.filter((attr) => {
        const sizeVariations = ["Size", "size","المقاس", "measuring"];
        return sizeVariations?.some(
          (name) => attr?.name?.toLowerCase() === name?.toLowerCase()
        );
      });
      const matchesSize = sizeAttributes?.some(attr => attr?.value?.toLowerCase() === size?.toLowerCase());
      return matchesSize;
    });
    
    setFilteredProducts(filtered);
    setCurrentPage(0);
  };
  const handleColorSelection = (color) => {
    setSelectedColor(color);
    filterProductsByColor(color);
    closeNav();
  };

  const handleSizeSelection = (size) => {
    setSelectedSize(size);
    filterProductsBySize(size);
    closeNav();
  };

  useEffect(() => {
    if (!products?.length) {
      dispatch(getProducts({ lan, id }));
    }
    clearSizeFilter();
    clearColorFilter();
  }, [dispatch, lan, id, products]);
  

  const handleUpperChange = (e) => {
    let newValue = parseInt(e.target.value);
    setUpperVal(newValue);
    filterByPrice(newValue);
  };

  const filterByPrice = (upperValue) => {
    const filtered = products?.filter(
      (product) => product?.price <= upperValue
    );
    setFilteredProducts(filtered);
    setCurrentPage(0); 
  };

  const clearSizeFilter = () => {
    setSelectedSize(null);
    setFilteredProducts(products);
    setCurrentPage(0);
  };

  const clearColorFilter = () => {
    setSelectedColor(null);
    setFilteredProducts(products);
    setCurrentPage(0);
  };

  useEffect(() => {
    dispatch(getProducts({ lan, id }));
    clearSizeFilter();
    clearColorFilter();
  }, [lan, dispatch, id]);
  const [isExpanded, setIsExpanded] = useState(false);
  const handleone = () => {
    setIsExpanded(false);
  };
  const handletwo = () => {
    setIsExpanded(true);
  };
  const [navWidth, setNavWidth] = useState(0);
  const openNav = () => {
    setNavWidth(330);
  };
  const closeNav = () => {
    setNavWidth(0);
  };
  return (
    
      loading === "idle" ? (
        <Fragment>
          <div className="tt-breadcrumb">
            <div className="container">
              <ul>
                <li>
                  <Link to={"/"}>{t("home")}</Link>
                </li>
                <li>/</li>
                <li>{t("shop")}</li>
              </ul>
            </div>
          </div>
          <div id="shop" className="py-5">
            <div className="container-indent">
              <div className="container">
                <div className="row">
                <div className={`col-md-4 col-lg-3  col-xl-3 leftColumn aside ${navWidth ? 'main_side' : ''}`}
                    id="js-leftColumn-aside"
                  
                    style={{ width: navWidth + "px" ,marginTop:"10%" }}
                  >
                     <button aria-label="close-btn" className="closebtnfilter d-lg-none d-block" onClick={closeNav}>
                        &times;
                    </button>
                    <div className="tt-collapse open tt-filter-detach-option">
                      <div className="tt-collapse-content">
                        <div className="filters-mobile">
                          <div className="filters-row-select"></div>
                        </div>
                      </div>
                    </div>
                    <div className="wrapper my-4">
                      <h3 className="tt-collapse-title">
                        {t("Filter by price")}
                      </h3>
                      <fieldset className="filter-price">
                        <div className="price-field">
                          <input
                            type="range"
                            min="0"
                            max="2000"
                            value={upperVal}
                            onChange={handleUpperChange}
                          />
                        </div>
                        <div className="price-wrap">
                          <div className="price-container">
                            <div className="price-wrap-1">
                              <input id="one" value={upperVal} readOnly />
                              <label htmlFor="one" className="mx-1">
                                {t("LE")}
                              </label>
                            </div>
                          </div>
                          <button
                            className="price-title btn"
                            onClick={() => filterByPrice(upperVal)}
                          >
                            {t("FILTER")}
                          </button>
                        </div>
                      </fieldset>
                    </div>

                    <div className="tt-collapse open">
                      <h3 className="tt-collapse-title">
                        {t("filter by size")}
                      </h3>
                      <div className="tt-collapse-content">
                        <ul className="tt-options-swatch options-middle">
                          <li
                            className={`opt-color ${
                              selectedSize === null ? "active-search" : ""
                            }`}
                            onClick={clearSizeFilter}
                          >
                            {t("Clear")}
                          </li>
                          {uniqueSizes?.map((size, index) => (
                            <li
                              className={`opt-color ${
                                selectedSize === size ? "active-search" : ""
                              }`}
                              key={index}
                              onClick={() => handleSizeSelection(size)}
                            >
                              {size}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div className="tt-collapse open">
                      <h3 className="tt-collapse-title">
                        {t("filter by color")}
                      </h3>
                      <div className="tt-collapse-content">
                        <ul className="tt-options-swatch">
                          <li
                            className={`opt-color ${
                              selectedColor === null ? "active-search" : ""
                            }`}
                            onClick={clearColorFilter}
                          >
                            {t("Clear")}
                          </li>
                          {uniqueColors?.map((color, index) => (
                            <li
                              key={index}
                              className={`opt-color ${
                                selectedColor === color ? "active-search" : ""
                              }`}
                              onClick={() => handleColorSelection(color)}
                            >
                              {color}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 col-lg-9 col-xl-9">
                    <div className="content-indent container-fluid-custom-mobile-padding-02">
                      <div
                        className="tt-filters-options"
                        id="js-tt-filters-options"
                      >
                        <h1 className="tt-title my-4">{t("all collection")}</h1>
                        
                      </div>
                      <div className="tt-filters-options" id="js-tt-filters-options">
						
            <div className="tt-btn-toggle">
            <button className=" btn-sm btn-dark" onClick={openNav}>
            {t("filter")}<i className="fa-solid fa-filter mx-1" ></i>
      </button>
            </div>
            <h3 className="tt-title mt-4 d-md-inline-flex d-none">{filteredProducts[0]?.category?.name}</h3>
            <div className="tt-sort">
              <img src={grid} alt="grid-all" className="filter-grid two" onClick={handletwo}/>
              <img src={mini} alt="grid-min"  className="filter-grid one" onClick={handleone}/>
            </div>
          
          </div>
                      <div className="tt-product-listing row">
                      {currentProducts?.map((item, idx) => (
                          <div key={idx} className={`col-${isExpanded ? '12' : '6'} col-md-${isExpanded ? '6' : '4'} tt-col-item`}>
                            <Card item={item}  />
                          </div>
                        ))}
                      </div>
                      <div className="text-center tt_product_showmore mt-5">
                      <ReactPaginate
                          previousLabel={t(" ← Previous")}
                          nextLabel={t("Next → ")}
                          pageCount={Math.ceil(filteredProducts?.length / productsPerPage)}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination"}
                          previousLinkClassName={"pagination__link"}
                          nextLinkClassName={"pagination__link"}
                          disabledClassName={"pagination__link--disabled"}
                          activeClassName={"pagination__link--active"}
                        />
                      </div> 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      ) : (
        <Loader />
      )
  );
};

export default React.memo(MainShop);
