import { useEffect, useState } from "react";
import cartimg from "../../assets/logo/icons8-cart-96.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import TopHeader from "./TopHeader";
import { MobileHeader } from "./MobileHeader";
import SideNav from "./SideNav";
import ShopingCart from "./ShopingCart";
import { getcategory } from "../../store/products/productAction";
import Search from "./Search";
import { getLogo } from "../../store/cart/cartAction";
import { baseUrl } from "../../utils/APi";

const Header = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { category } = useSelector((state) => state.Productslice);
  const lan = useSelector((state) => state.LanSlicer.language);
  const { cart, favlist, logo } = useSelector((state) => state.cartSlice);
  const [navWidth, setNavWidth] = useState(0);
  const [CartWidth, setCartWidth] = useState(false);

  const openNav = () => {
    setNavWidth(330);
  };
  const closeNav = () => {
    setNavWidth(0);
  };
  const openCart = () => {
    setCartWidth(true);
  };
  const closeCart = () => {
    setCartWidth(false);
  };
  useEffect(() => {
    dispatch(getcategory(lan));
    if(!logo){
      const promise = dispatch(getLogo());
      return () => {
        promise.abort();
      };
    }
  }, [lan, dispatch]);

  return (
    <header id="tt-header" className="py-md-4 pb-1">
      <SideNav
        lan={lan}
        width={navWidth}
        Close={closeNav}
        logo={logo}
        category={category}
      />
      <ShopingCart width={CartWidth} Close={closeCart} />
      <TopHeader />
      <MobileHeader
        logo={logo}
        open={openNav}
        openCart={openCart}
        cart={cart}
        favlist={favlist}
      />
      <div className="tt-desktop-header">
        <div className="container">
          <div className="tt-header-holder">
            <div className="tt-col-obj tt-obj-logo">
              <Link className=" tt-logo-alignment main_logo" to={"/"}>
                {logo && <img src={`${baseUrl}${logo}`} alt="main-logo" />}
              </Link>
            </div>
            <div className="tt-col-obj tt-obj-menu ms-4">
              <div className="tt-desctop-parent-menu tt-parent-box">
                <div
                  className="tt-desctop-menu tt-hover-03"
                  id="js-include-desktop-menu"
                >
                  <nav>
                    <ul>
                      <li className="dropdown megamenu">
                        <Link className="navLink" to={"/totaloffers"}>
                          {t("offers")}
                        </Link>
                      </li>
                      {category?.slice(0, 5).map((item, idx) => (
                        <li className="dropdown tt-megamenu-col-01" key={idx}>
                          <Link
                            className="navLink"
                            to={`/Category/${item?.id}`}
                          >
                            {item?.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
            <div className="tt-col-obj tt-obj-options obj-move-right">
              <Search />
              <div className="tt-desctop-parent-cart tt-parent-box mr-2">
                <div className="tt-cart tt-dropdown-obj">
                  <button
                    className="tt-dropdown-toggle"
                    onClick={(e) => {
                      e.stopPropagation();
                      openCart();
                    }}
                  >
                    <img src={cartimg} alt="cart-icon" className="cart-img" />
                    <span className="tt-badge-cart">{cart?.length}</span>
                  </button>
                </div>
              </div>
              <div className="tt-desctop-parent-cart tt-parent-box mx-2">
                <div className="tt-cart tt-dropdown-obj">
                  <Link to={"/wishlist"} className="tt-dropdown-toggle">
                    <i className="fa-regular fa-heart"></i>
                    <span className="tt-badge-cart">{favlist?.length}</span>
                  </Link>
                </div>
              </div>
              <div className="tt-desctop-parent-account tt-parent-box">
                <div className="tt-account tt-dropdown-obj">
                  <Link to={"/profile"} className="tt-dropdown-toggle">
                    <i className="fa-regular fa-user"></i>
                  </Link>
                </div>
              </div>

              <div className="tt-desctop-parent-multi tt-parent-box">
                <div className="tt-multi-obj tt-dropdown-obj">
                  <button
                    className="tt-dropdown-toggle"
                    onClick={(e) => {
                      e.stopPropagation();
                      openNav();
                    }}
                  >
                    <i className="fa-solid fa-bars"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
export default Header;
