import { createSlice } from "@reduxjs/toolkit";
import { gettotalOffers, getofferProducts } from "./totaloffAction";

const initialState = {
  offers: [],
  offerProducts: [],
  loading: "pending",
  error: null,
};
const Offers = createSlice({
  name: "Offers",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(gettotalOffers.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(gettotalOffers.fulfilled, (state, action) => {
        state.offers = action.payload;
        state.loading = "idle";
        state.error = null;
      })
      .addCase(getofferProducts.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(getofferProducts.fulfilled, (state, action) => {
        state.offerProducts = action.payload;
        state.loading = "idle";
        state.error = null;
      })
      .addCase(gettotalOffers.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload;
      });
  },
});

export default Offers.reducer;
