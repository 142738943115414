const Loader = () => {
  return (
<div className="loader">
  <div className="wrapper">
    <div className="circle"></div>
    <div className="line-1"></div>
    <div className="line-2"></div>
    <div className="line-3"></div>
    <div className="line-4"></div>
    <div className="line-5"></div>
  </div>
</div>
  );
};

export default Loader;
