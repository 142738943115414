import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { ResetPassword } from "../../store/auth/authAction";
import { failed, sucess } from "../../component/toast/Toast";
import { Link } from "react-router-dom";

const ResetPasswordd = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.authSlice);
  const formik = useFormik({
    initialValues: {
      password: "",
      ConfirmPassword: "",
    },
    validate: (values) => {
      const errors = {};
      if (values.password !== values.ConfirmPassword) {
        errors.ConfirmPassword = t("Passwords do not match");
      }
      return errors;
    },
    validateOnChange: true,
    onSubmit: async (values) => {
      try {
        const { password } = values;
        const result = await dispatch(ResetPassword({ password, token }));
          if (result.payload.status === "error") {
          failed(`${result.payload.message}`);
        } else {

          sucess(t("New password Have been reset"));
        }
      } catch (error) {
        console.error("Error occurred while registering user:", error);
      }
    },
  });

  return (
    <Fragment>
      <div className="tt-wrapper">
        <h6 className="tt-title ">{t("Add A New Password")}</h6>
        <div className="form-default">
          <form onSubmit={formik.handleSubmit}>
            <div className="form-group">
              <label htmlFor="password mb-1" className="form-label">
                {t("password")} *
              </label>
              <input
                type="password"
                className={`form-control ${
                  formik.touched.password && formik.errors.password
                    ? "is-invalid"
                    : ""
                }`}
                id="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                name="password"
                placeholder={t("enter password")}
              />

              {formik.touched.password && formik.errors.password ? (
                <div className="invalid-feedback">{formik.errors.password}</div>
              ) : null}

              <label
                htmlFor="confirmPassword"
                className="form-label d-inline-block mt-4"
              >
                {t("Confirm Password")} *
              </label>
              <input
                type="password"
                className={`form-control  ${
                  formik.touched.ConfirmPassword &&
                  formik.errors.ConfirmPassword
                    ? "password did not match"
                    : ""
                }`}
                id="ConfirmPassword"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.ConfirmPassword}
                name="ConfirmPassword"
                placeholder={t("Confirm Password")}
              />
              {formik.errors.ConfirmPassword &&
              formik.touched.ConfirmPassword ? (
                <p className="valid-error">{formik.errors.ConfirmPassword}</p>
              ) : null}
            </div>
            <div className="row tt-offset-21">
              <div className="col-auto">
                <button type="submit" className="btn main-btn">
                  {t("Save")}
                </button>
              </div>
              <div className="col-auto align-self-center mx-1">
                {t("or")}{" "}
                <Link to={"/profile"} className="tt-link">
                  {t("Cancel")}
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};
export default ResetPasswordd;
