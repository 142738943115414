import { Outlet, useLocation } from "react-router-dom";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import { Fragment, useEffect} from "react";
import QuickModal from "../../component/QuickModal";
import CartModal from "../../component/CartModal";
import BackTop from "../../component/BackTop";
import "swiper/swiper-bundle.css";
import "swiper/css";
import "swiper/css/pagination";
const MainLayout = () => {

  
  const ScrollToTop = () => {
      const { pathname } = useLocation();
  
      useEffect(() => {
          window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth' 
          });
      }, [pathname]);
  
      return null;
  };
  

  
  return (
    <Fragment>
      <ScrollToTop /> 
      <Header />
      <div id="main-home">
        <Outlet />
      </div>
      <QuickModal />
      <CartModal />
      <BackTop />
      <Footer />
    </Fragment>
  );
};

export default MainLayout;
