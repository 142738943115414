import { createAsyncThunk } from "@reduxjs/toolkit";
import { instance } from "../../utils/APi";

export const getGovernment = createAsyncThunk(
  "location/getGovernment",
  async (lan, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await instance.get(`api/governoratesdata`, {
        headers: {
          locale: lan,
        },
      });
      return response.data.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return rejectWithValue(error.message);
    }
  }
);
export const getcity = createAsyncThunk(
  "location/getcity",
  async ({ lan, id }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await instance.get(`api/get_cities/${id}`, {
        headers: {
          locale: lan,
        },
      });
      return response.data.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return rejectWithValue(error.message);
    }
  }
);
export const getzone = createAsyncThunk(
  "location/getzone",
  async ({ lan, id }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await instance.get(`api/get_zones/${id}`, {
        headers: {
          locale: lan,
        },
      });
      return response.data.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return rejectWithValue(error.message);
    }
  }
);
