import { Fragment, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../component/Loader";
import img from "../../assets/userLogin.png";
import {
  UpdatePhone,
  UpdateProfile,
  userInfo,
} from "../../store/auth/authAction";
import { useNavigate } from "react-router-dom";
import { failed, sucess } from "../../component/toast/Toast";

const UserProfile = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user, token, loading } = useSelector((state) => state.authSlice);
  const [showOne, setShowOne] = useState(false);
  const [showTwo, setShowTwo] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(userInfo(token));
  }, [dispatch, token]);

  const [fullName, setfullName] = useState({
    first_name: "",
    last_name: "",
  });
  const [phone_number, setPhone] = useState("");

  const handleName = (e) => {
    const { name, value } = e.target;
    setfullName({
      ...fullName,
      [name]: value,
    });
  };
  const handlechangephone = (e) => {
    setPhone(e.target.value);
  };
  const handlefullname = async (e) => {
    e.preventDefault();
    if (fullName?.first_name?.length > 0 || !fullName?.last_name?.length > 0) {
      try {
        const result = await dispatch(
          UpdateProfile({ values: fullName, token })
        );
       
        if (result.payload.status === "error") {
          failed(`${result.payload.message}`);
        } else {
          sucess(t("sucess"));
          dispatch(userInfo(token));
          setfullName({ first_name: "", last_name: "" });
          setShowOne(false);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      failed(t("inputs can not be empty"));
    }
  };
  const handlephone = async (e) => {
    e.preventDefault();
    if (phone_number?.length > 0) {
      try {
        const result = await dispatch(
          UpdatePhone({ values: phone_number, token })
        );
        if (result.payload.status === "error") {
          failed(`${result.payload.message}`);
        } else {
          sucess(`${result.payload.message}`);
          dispatch(userInfo(token));
          setShowTwo(false);
          setPhone("");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      failed(t("inputs can not be empty"));
    }
  };
  const inputRef=useRef();
  useEffect(() => {
    function handleClickOutside(event) {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setShowOne(false);
        setShowTwo(false);
      }
    }

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return loading === "pending" ? (
    <Loader />
  ) : (
    <Fragment>
      <div className="tt-wrapper profile-deta">
        <h3 className="tt-title">{t("ACCOUNT DETAILS")}</h3>
        <div className="tt-table-responsive d-flex justify-content-between ">
          <table className="tt-table-shop-02">
            <tbody>
              <tr>
                <td>{t("name")}:</td>
                <td className="tabEdit">
                  {showOne ? (
                    <form
                    ref={inputRef}
                      onSubmit={handlefullname}
                      className="d-flex flex-md-row flex-column justify-content-between align-items-center "
                    >
                      <input
                        className="form-control "
                        type="text"
                        onChange={handleName}
                        value={fullName?.first_name}
                        name="first_name"
                        id="first_name"
                        required="required"
                        placeholder={t("FIRST NAME")}
                        wfd-id="id33"
                      ></input>
                      <input
                        className="form-control mx-2 my-2 my-md-0"
                        type="text"
                        onChange={handleName}
                        value={fullName?.last_name}
                        name="last_name"
                        id="last_name"
                        required="required"
                        placeholder={t("LAST NAME")}
                        wfd-id="id34"
                      ></input>
                      <button className="btn editSave mb-3 mb-md-0" type="submit">
                      {t("Save")}
                      </button>
                    </form>
                  ) : (
                    <>
                      <span>
                        {" "}
                        <span className="mx-1"> {user?.first_name}</span>
                        {user?.last_name}
                      </span>
                      <i
                        className="fa-regular fa-pen-to-square"
                       
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowOne(true)
                        }}
                      ></i>
                    </>
                  )}
                </td>
              </tr>
              <tr>
                <td>{t("e-mail")}:</td>
                <td className="tabEdit">
                  {" "}
                  <span>{user?.email} </span>
                  
                </td>
              </tr>
              <tr>
                <td>{t("address")}:</td>
                <td className="tabEdit">
                  <span>{user?.address}</span>
                  <i
                    className="fa-regular fa-pen-to-square"
                    onClick={() =>
                      navigate("/profile", {
                        state: { selectedTab: "MY Address" },
                      })
                    }
                  ></i>
                </td>
              </tr>
              <tr>
                <td>{t("phone")}:</td>
                <td className="tabEdit">
                  {showTwo ? (
                    <form
                     ref={inputRef}
                      onSubmit={handlephone}
                      className="d-flex flex-row justify-content-between align-items-center "
                    >
                      <input
                        className="form-control mx-2"
                        type="number"
                        onChange={handlechangephone}
                        value={phone_number}
                        name="phone_number"
                        id="phone_number"
                        required="required"
                        placeholder={t("phone number")}
                        wfd-id="id33"
                      ></input>

                      <button className="btn editSave" onClick={(e)=>e.stopPropagation()} type="submit">
                        {t("Save")}
                      </button>
                    </form>
                  ) : (
                    <>
                      {" "}
                      <span>{user?.phone}</span>
                      <i
                        className="fa-regular fa-pen-to-square"
                      
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowTwo(true);
                        }}
                      ></i>
                    </>
                  )}
                </td>
              </tr>
            </tbody>
          </table>

          <div className="profile-user d-none d-md-inline-flex ">
            <img src={img} alt="profile" />
          </div>
        </div>
       
      </div>
    </Fragment>
  );
};

export default UserProfile;
