import { createSlice } from "@reduxjs/toolkit";
import { getzone, getcity, getGovernment } from "./locationActions";

const initialState = {
  government: [],
  city: [],
  zone: [],
  loading: "pending",
  error: null,
};

const locationSlice = createSlice({
  name: "location",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getGovernment.pending, (state) => {
      state.loading = "pending";
      state.error = null;
    }).addCase(getGovernment.fulfilled, (state, action) => {
      state.government = action.payload;
      state.loading = "idle";
      state.error = null;
    }).addCase(getcity.fulfilled, (state, action) => {
      state.city = action.payload;
      state.loading = "idle";
      state.error = null;
    }).addCase(getzone.fulfilled, (state, action) => {
      state.zone = action.payload;
      state.loading = "idle";
      state.error = null;
    });
  },
});

export default locationSlice.reducer;
