import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { baseUrl } from "../../utils/APi";
import { removeFromCart } from "../../store/cart/cartSlice";

const ShopingCart = ({ width, Close }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { cart } = useSelector((state) => state.cartSlice);
  const cartRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (cartRef?.current && !cartRef?.current.contains(event.target)) {
        Close();
      }
    }
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [Close]);

 
  const calculateTotal = () => {
    return cart.reduce((acc, item) => {
      const subtotal = item.price * item.quantity;
      return acc + subtotal;
    }, 0);
  };

  return (
    <div
      className={`sidenav ${width ? "cartWidth" : ""}`}
      style={{ width: width + "px" }}
      ref={cartRef}
    >
      <button aria-label="close-btn" className="closebtn" onClick={Close}>
        &times;
      </button>
      <div className="shopping-cart">
        <div className="title d-flex justify-content-between w-full mb-3"><div>{t("shopping bag")}</div> <div className="total-products mx-5"> {cart?.length} {t("items")} </div></div>
        <div className="bottomTitle"></div>
        {cart?.map((item, index) => (
          <div className="item " key={index}>
            <div className="image">
              <img
                src={`${baseUrl}${item?.productPhoto}`}
                alt={item?.productName}
              />
            </div>
            <div className="cartBody d-flex align-items-center justify-content-center">
              <div className="description">
                <span>{item?.productName}</span>
              </div>

              <div className="quantity">
                <span className="mx-2">{item?.color ? item?.color : ""}</span>
                <input type="text" name="name" defaultValue={item?.quantity} />
              </div>
              <div className="total-price">
                {item?.price ? item?.price : ""} {t("LE")}
              </div>
              <div className="fls_last mt-4"  onClick={() =>
                  dispatch(removeFromCart({ productId: item?.productId }))
                }><button className="close_slide gray"><i className="fa-solid fa-trash"></i></button></div>
             
            </div>
          </div>
        ))} 
        <div className="w-50 w-7s5  mx-auto mt-4" >
       
       <div className="titlde p-md-3 p-2 d-flex justify-content-between align-items-center w-full total-cart"><div className="bold">{t("total")}  </div> <div> {calculateTotal()} {t("LE")}</div></div>
       </div>
        <div className="actions mt-1 mt-md-0">
          <button className="Cart">
            <Link to={"/cart"} onClick={Close}>
              <i className="fa-brands fa-shopify px-2"></i>
              {t("shopping cart")}
            </Link>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShopingCart;
