import { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./store/store.js";
import Loader from "./component/Loader.jsx";
import { AuthGuard } from "./component/AuthGuard.jsx";
import {
  createRoutesFromElements,
  createBrowserRouter,
  Route,
  RouterProvider,
} from "react-router-dom";
import Jobs from "./pages/jobs/Jobs.jsx";
import { Toast } from "./component/toast/Toast.jsx";
import Home from "./pages/home/Home";
import SingleProduct from "./pages/Single-product/SingleProduct";
import MainShop from "./pages/shop/MainShop.jsx";
import MainLayout from "./layouts/mainLayout/MainLayout.jsx";
import Category from "./pages/shop/Category.jsx";
import About from "./pages/about/About";
import Policy from "./pages/about/Policy";
import Shop from "./pages/shop/Shop";
import Login from "./pages/profile/Login";
import Register from "./pages/profile/Register";
import Contact from "./pages/contact/Contact";
import Cart from "./pages/cart/Cart";
import Wishlist from "./pages/wishlist/Wishlist";
import Branch from "./pages/branch/Branch";
import Profile from "./pages/profile/Profile";
import Offer from "./pages/home/Offer";
import TotalOffers from "./pages/offers/TotalOffers";
import JobForm from "./pages/jobs/JobForm.jsx";
import "./all.min.css"
import "./style.css";

const routes = createRoutesFromElements(
  
  <Route path="/" element={<MainLayout />}>
    <Route index element={<Home />} />
    <Route path="policy" element={<Policy />} />
    <Route path="shop/:id" element={<Shop />} />
    <Route path="mainshop/:id" element={<MainShop />} />
    <Route path="about" element={<About />} />
    <Route path="JobForm" element={<JobForm />} />
    <Route path="login" element={<Login />} />
    <Route path="register" element={<Register />} />
    <Route path="contact" element={<Contact />} />
    <Route path="offers" element={<Offer />} />
    <Route path="totaloffers" element={<TotalOffers />} />
    <Route path="category/:id" element={<Category />} />
    <Route path="cart" element={<Cart />} />
    <Route path="jobs" element={<Jobs />} />
    <Route path="branch" element={<Branch />} />
    <Route
      path="wishlist"
      element={
        <AuthGuard>
          <Wishlist />
        </AuthGuard>
      }
    />
    <Route path="products/:productId" element={<SingleProduct />} />
    <Route
      path="profile"
      element={
        <AuthGuard>
          <Profile />
        </AuthGuard>
      }
    />
  </Route>
);

const router = createBrowserRouter(routes);


const App = () => {
  const [isRehydrated, setIsRehydrated] = useState(false);

  useEffect(() => {
    persistor.persist();
    setIsRehydrated(true);
  }, []);
// console.log("app")
  return (
    <Provider store={store}>
      <PersistGate loading={<Loader />} persistor={persistor}>
        {isRehydrated && (
          <I18nextProvider i18n={i18n}>
            <Toast />
            <RouterProvider router={router}>
              
            </RouterProvider>
          </I18nextProvider>
        )}
      </PersistGate>
    </Provider>
  );
};

export default App;
