import { createSlice } from "@reduxjs/toolkit";
import {
  getAboutSite,
  getAboutPhoto,
  getPolicy,
  getBranches,
} from "./aboutAction";

const initialState = {
  aboutSite: [],
  aboutPhoto: [],
  policy: [],
  branches: [],
  loading: "pending",
  error: null,
};

const aboutSlice = createSlice({
  name: "about",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAboutSite.pending, (state) => {
      state.loading = "pending";
      state.error = null;
    }).addCase(getAboutSite.fulfilled, (state, action) => {
      state.aboutSite = action.payload;
      state.loading = "idle";
      state.error = null;
    }).addCase(getAboutPhoto.fulfilled, (state, action) => {
      state.aboutPhoto = action.payload;
      state.loading = "idle";
      state.error = null;
    }).addCase(getPolicy.fulfilled, (state, action) => {
      state.policy = action.payload;
      state.loading = "idle";
      state.error = null;
    }).addCase(getBranches.fulfilled, (state, action) => {
      state.branches = action.payload;
      state.loading = "idle";
      state.error = null;
    });
  },
});

export default aboutSlice.reducer;
