import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { baseUrl } from "../../utils/APi";
import { useTranslation } from "react-i18next";
import { getsubCategories } from "../../store/products/productAction";
import Loader from "../../component/Loader";

const Category = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const lan = useSelector((state) => state.LanSlicer.language);
  const { subCategory, loading } = useSelector((state) => state.Productslice);
  const { id } = useParams();
  useEffect(() => {
    if (id) {
     const promise = dispatch(getsubCategories({ lan, id: id }));
     return ()=>{
      promise.abort();
    }}
  }, [lan, dispatch, id]);

  return (
   loading === "idle" ? (
        <Fragment>
          <div className="tt-breadcrumb">
            <div className="container mb-4">
              <ul>
                <li>
                  <Link to={"/"}>{t("home")}</Link>
                </li>
                <li>/</li>
                <li>{t("Category")}</li>
              </ul>
            </div>
          </div>
          <div className="container mt-5 featured-section">
            <div className="row mt-4">
              {subCategory?.categories?.map((item, idx) => (
                <div className="col-6 col-md-6 col-lg-4 cate-item" key={idx}>
                  <Link to={`/mainshop/${item?.id}`} className="cate-sitem">
                    <img src={`${baseUrl}${item?.image}`} alt={`${item?.id}`} />
                    <h5 className="title">{item?.name}</h5>
                  
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </Fragment>
      ) : (
        <Loader />
      )
  );
};

export default Category;
