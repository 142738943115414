import { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getBranches } from "../../store/about/aboutAction";
import Loader from "../../component/Loader";
import { Link } from "react-router-dom";
const Branch = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const lan = useSelector((state) => state.LanSlicer.language);
  const { branches, loading } = useSelector((state) => state.aboutSlicer);
  
  useEffect(() => {
    dispatch(getBranches(lan));
  }, [dispatch, lan]);

  return loading === "pending" ? (
    <Loader />
  ) : (
    <Fragment>
      <div className="tt-breadcrumb">
        <div className="container">
          <ul>
            <li>
            <Link to={"/"}>{t("home")}</Link>
            </li>
            <li>/</li>
            <li>{t("branches")}</li>
          </ul>
        </div>
      </div>
      <div className="about my-5 branch">
        <div className="container-indent mx-auto px-lg-5">
          <div className="container-fluid mx-auto  container-fluid-custom-mobile-padding px-lg-5">
            <div className="row">
              <div className="col-xs-12 col-md-11 col-lg-12">
                <div className="tt-about-box-02 mt-4 mx-auto">
                  <h1 className="tt-title">
                    {t("wALKER Branches Greatful to all Visitors")}
                  </h1>
                  <iframe
                    title="sit map"
                    className="py-4 w-100"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d55236.39324232185!2d31.249667434089247!3d30.086321643136014!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x145841a87c0f07c7%3A0x327db9f821c761f3!2sWalker!5e0!3m2!1sen!2seg!4v1715860874417!5m2!1sen!2seg"
                    width="100%"
                    height="650"
                    style={{ border: "0" }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-indent px-lg-5">
          <div className="container-fluid-custom px-lg-5">
            <div className="tt-col-img">
              <div className="row">
                <div className="col-md-10 col-sm-12 mt-4 mx-auto">
                  {branches?.map((item, idx) => (
                    <div
                      className="col-sm-12 col-12 branch-item mb-5"
                      key={item?.id}
                    >
                      <p>{item?.name}</p>
                      <p>
                        <b> {item?.city} : </b> {item?.address}
                      </p>
                      <p>
                        <b>{t("phone number")} : </b> {item?.phone}
                      </p>
                      <p>
                        <b>{t("location")} : </b>
                        <a target="blank" href={item?.link}>
                          {item?.link}
                        </a>
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Branch;
