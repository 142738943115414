import { Fragment, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getPolicy } from "../../store/about/aboutAction";
import Loader from "../../component/Loader";
import { Link } from "react-router-dom";
const Policy = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const lan = useSelector((state) => state.LanSlicer.language);
  const { policy, loading } = useSelector((state) => state.aboutSlicer);
  
  useEffect(() => {
     dispatch(getPolicy(lan));
  }, [dispatch, lan]);

  return loading === "pending" ? (
    <Loader />
  ) : (
    <Fragment>
      <div className="tt-breadcrumb">
        <div className="container">
          <ul>
            <li>
              <Link to={"/"}>{t("home")}</Link>
            </li>
            <li>/</li>
            <li>{t("policy")}</li>
          </ul>
        </div>
      </div>
      <div className="policy my-md-5">
        <div className="container-indent ">
          <div className="container container-fluid-custom-mobile-padding px-lg-5">
          <h1 className="tt-title my-md-5">{t("walker clothing policy")}</h1>
            <div className="row">
            <div className="col-11 mx-auto mt-md-4">
           
            {policy?.map((item, idx) => (
  <div className="my-3" key={idx} dangerouslySetInnerHTML={{ __html: `<span id="policy-title">${item?.title}</span><span id="policy-desc">${item?.desc}</span>` }} />
))}

</div>
</div>
              
          
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Policy;
