import { createAsyncThunk } from "@reduxjs/toolkit";
import { instance } from "../../utils/APi";

export const getProducts = createAsyncThunk(
  "products/getProducts",
  async ({ lan, id }, thunkAPI) => {
    const { rejectWithValue,signal } = thunkAPI;
    try {
      const response = await instance.get(`api/categories/products/${id}`, {
        headers: {
          locale: lan,
        },
      },{signal});
      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return rejectWithValue(error.message);
    }
  }
);
export const getsubCategories = createAsyncThunk(
  "products/getsubCategories",
  async ({ lan, id }, thunkAPI) => {
    const { rejectWithValue,signal } = thunkAPI;
    try {
      const response = await instance.get(`api/categories/sub/${id}`, {
        headers: {
          locale: lan,
        },
      },{signal});
      return response.data.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return rejectWithValue(error.message);
    }
  }
);
export const getcategory = createAsyncThunk(
  "products/getcategory",
  async (lan, thunkAPI) => {
    const { rejectWithValue, signal } = thunkAPI;
    try {
      const response = await instance.get(`api/categories`, {
        headers: {
           locale: lan,
          'Content-Type': 'application/json',
        },
      },{signal});
      return response.data.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return rejectWithValue(error.message);
    }
  }
);

export const getsingleProduct = createAsyncThunk(
  "products/getsingleProduct",
  async ({ lan, id }, thunkAPI) => {
    const { rejectWithValue, signal } = thunkAPI;
    try {
      const response = await instance.get(
        `api/product/${id}`,
       
        {
          headers: {
            locale: lan,
          },
        } ,{ signal }
      );
      return response.data.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return rejectWithValue(error.message);
    }
  }
);

export const searchProduct = createAsyncThunk(
  "products/searchProduct",
  async ({ lan, name }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await instance.get(`api/products/search/${name}`, {
        headers: {
          locale: lan,
        },
      });
      return response.data.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return rejectWithValue(error.message);
    }
  }
);
export const addReview = createAsyncThunk(
  "products/addReview",
  async ({ formData, token }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await instance.post(`api/reviewProduct`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return rejectWithValue(error.message);
    }
  }
);
