import { instance } from "../../utils/APi";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getContactInfo = createAsyncThunk(
  "contact/getContactInfo",
  async (lan, thunkAPI) => {
    const { rejectWithValue, signal} = thunkAPI;
    try {
      const response = await instance.get(`api/site-data`, {
        'Content-Type': 'application/json',
        headers: lan,
      }  ,{signal});
      return response.data.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return rejectWithValue(error.message);
    }
  }
);
export const getSocialMedia = createAsyncThunk(
  "contact/getSocialMedia",
  async (_, thunkAPI) => {
    const { rejectWithValue ,signal} = thunkAPI;
    try {
      const response = await instance.get(`api/social-media`,{ headers: {
        'Content-Type': 'application/json',
      }},{signal});
      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return rejectWithValue(error.message);
    }
  }
);
export const formContact = createAsyncThunk(
  "contact/formContact",
  async (userData, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await instance.post(`api/contactMessage`, userData);
      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return rejectWithValue(error.message);
    }
  }
);

export const getJobs = createAsyncThunk(
  "contact/getJobs",
  async (lan, thunkAPI) => {
    const { rejectWithValue,signal } = thunkAPI;
    try {
      const response = await instance.get(`api/jobs`, {
        headers: lan,
      },{signal});
      return response.data.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return rejectWithValue(error.message);
    }
  }
);
export const addJob = createAsyncThunk(
  "contact/addJob",
  async (values, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await instance.post(`api/applyJobs`, values);
      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return rejectWithValue(error.message);
    }
  }
);
export const getPayment = createAsyncThunk(
  "contact/getPayment",
  async (token, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await instance.get(`api/payment_methods`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.data;
    } catch (error) {
       console.error("Error fetching data:", error);
      return rejectWithValue(error.message);
    }
  }
);
