import {  useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { closeCartModal } from "../store/modal/ModalSlicer";
import { useTranslation } from "react-i18next";
import { Link} from "react-router-dom";
import { baseUrl } from "../utils/APi";
import { getfav } from "../store/cart/cartAction";

const CartModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const lan = useSelector((state) => state.LanSlicer.language);
  const { cartOpen, item } = useSelector((state) => state.ModalSlicer);
  
  const { token } = useSelector((state) => state.authSlice);
  useEffect(() => {
    if (cartOpen) {
       dispatch(getfav({ lan, token }));
    }
  }, [dispatch, lan, item, cartOpen,token]);
 
  return (
      (cartOpen && item) && (
        <div className="quick-modal">
          <div className={`modal-dialog modal-lg ${lan === 'ar' ? 'rtl-style' : 'ltr-style'}`}>
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  onClick={() => dispatch(closeCartModal())}
                  aria-hidden="true"
                >
                  <i className="fa-solid fa-xmark"></i>
                </button>
              </div>
              <div className="modal-body">
                <div className="tt-modal-addtocart mobile">
                  <div className="tt-modal-messages">
                    <i className="icon-f-68"></i>
                    {t("Added to wish List successfully!")}
                  </div>
                  <Link
                    to={"/"}
                    className="btn-link btn-close-popup"
                    onClick={() => dispatch(closeCartModal())}
                  >
                    {t("Continue Shopping")}
                  </Link>
                  <Link
                    to={"/wishlist"}
                    className="btn-link"
                    onClick={() => dispatch(closeCartModal())}
                  >
                    {t("View wish List")}
                  </Link>
                </div>
                <div className="tt-modal-addtocart desctope">
                  <div className="row">
                    <div className="col-12 col-lg-6">
                      <div className="tt-modal-messages">
                        <i className="icon-f-68"></i>{" "}
                        {t("Added to wish List successfully!")}
                      </div>
                      <div className="tt-modal-product">
                        <div className="tt-img">
                          <img
                            src={`${baseUrl}${item?.product_photo || item?.image  }`}
                            alt={item?.name}
                          />
                        </div>
                        <h2 className="tt-title">
                          <Link to={`/products/${item?.id}`}>
                            {item?.name}
                          </Link>
                        </h2>
                        <div
                          className="tt-wrapper"
                          dangerouslySetInnerHTML={{
                            __html: item?.desc,
                          }}
                        />
                      </div>
                      <div className="tt-product-total">
                        <div className="tt-total">
                          <span className="tt-price">
                            {item?.price} {item?.currency}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-6">
                      <Link
                        onClick={() => dispatch(closeCartModal())}
                        className="btn btn-border btn-close-popup main-btn"
                      >
                        {t("CONTINUE SHOPPING")}
                      </Link>

                      <Link
                        to={"/wishlist"}
                        onClick={() => dispatch(closeCartModal())}
                        className="btn main-btn"
                      >
                        {t("View wish List")}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
  );
};

export default CartModal;
