import {  useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getarrivals } from "../../store/home/homeAction";
import Card from "../../component/Card";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import Loader from "../../component/Loader";

const Arrivals = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const lan = useSelector((state) => state.LanSlicer.language);
  const { arrivals, loading } = useSelector((state) => state.homeSlicer);

  useEffect(() => {
   if(arrivals?.length === 0){
    const promise = dispatch(getarrivals(lan));
      return ()=>{
        promise.abort();
      }}
  }, [lan, dispatch,arrivals?.length]);

  if (loading !== "idle") return <Loader />;
  return (
        loading === "idle" && (
        <section className="w-100 container-indent container-fluid arrivals py-4 ">
          <div className="tt-block-title py-4">
            <h1 className="tt-title">{t("new arrivals")}</h1>
            <div className="tt-description">{t("top view in this week")}</div>
          </div>
          <Swiper
            spaceBetween={10}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              0: {
                slidesPerView: 2,
                spaceBetween: 5,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 5,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 5,
              },
            }}
            modules={[Pagination]}
            className="mySwiper"
          >
            {" "}
            {arrivals?.map((item, idx) => (
              <SwiperSlide className="custom-padding dir my-2 my-md-0" key={item?.id}>
                <Card item={item} />
              </SwiperSlide>
            ))}
          </Swiper>
        </section>
      )
    
  );
};

export default Arrivals;
