import { Fragment, useState, Suspense, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { SignOut } from "../../store/auth/authSlice";
import { Link, useLocation, useNavigate } from "react-router-dom";
import UserProfile from "./UserProfile";
import Orders from "./Orders";
import ResetPasswordd from "./ResetPassword";
import EditProfile from "./EditProfile";

const Profile = () => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(t("Account Details"));
  const changeSelected = (tab) => {
    setSelectedTab(tab);
  };
  const location = useLocation();

  useEffect(() => {
    const state = location?.state;
    if (state && state?.selectedTab) {
      setSelectedTab(state?.selectedTab);
    }else{
      setSelectedTab(t("Account Details"))
    }
  }, [location.state,t]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const tabComponents = {
    [t("Account Details")]: <UserProfile />,
    [t("MY Address")]: <EditProfile />,
    [t("Reset password")]: <ResetPasswordd />,
    [t("My orders")]: <Orders />,
  };
  const logout = () => {
    dispatch(SignOut());
    navigate("/login");
  };
  return (
    <Fragment>
      <div className="tt-breadcrumb">
        <div className="container my-4">
          <ul>
            <li>
              <Link to={"/"}>{t("home")}</Link>
            </li>
            <li>/</li>
            <li>{t("my account")}</li>
          </ul>
        </div>
      </div>
      <div className="container-indent my-5 profile">
        <div className="container container-fluid-custom-mobile-padding d-flex align-content-center justify-content-center flex-column">
          <h1 className="tt-title-subpages noborder mt-3">{t("my account")}</h1>
          <div className="account-container mb-2 mx-auto">
            <div className="tabs-wrapper  ">
              {Object?.keys(tabComponents).map((tab) => (
                <div
                  key={tab}
                  className={`tab ${selectedTab === tab ? "active" : ""}`}
                  onClick={() => changeSelected(tab)}
                >
                  {tab}
                </div>
              ))}
              <div className="tab" onClick={() => logout()}>
                {t("Sign Out")}
              </div>
            </div>
          </div>
          <div className="tt-shopping-layout">
            <Suspense fallback={<div>Loading...</div>}>
              {selectedTab &&
                tabComponents[selectedTab] &&
                tabComponents[selectedTab]}
            </Suspense>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default Profile;
