import { instance } from "../../utils/APi";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getsingleProduct } from "../products/productAction";

export const addCart = createAsyncThunk(
  "cart/addCart",
  async ({ id, token }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await instance.post(`api/cart/products`, id, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return rejectWithValue(error.message);
    }
  }
);

export const addToCartAndGetProductDetails = createAsyncThunk(
  "cart/addToCartAndGetProductDetails",
  async ({ lan, productId, token }, thunkAPI) => {
    try {
      await thunkAPI.dispatch(addCart({ id: productId, token }));
      const productDetails = await thunkAPI.dispatch(
        getsingleProduct({ lan, id: productId })
      );
      return productDetails;
    } catch (error) {
      console.error(
        "Error occurred while adding to cart and fetching product details:",
        error
      );

      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const addTofav = createAsyncThunk(
  "cart/addTofav",
  async ({ id, token }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await instance.get(`api/favorites/add/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return rejectWithValue(error.message);
    }
  }
);
export const delfromfav = createAsyncThunk(
  "cart/delfromfav",
  async ({ id, token }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await instance.get(`api/favorites/del/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return rejectWithValue(error.message);
    }
  }
);

export const getfav = createAsyncThunk(
  "cart/getfav",
  async ({ lan, token }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await instance.get(`api/favorites`, {
        headers: {
          locale: lan,
          Authorization: `Bearer ${token}`,
        },
      });
      const favlist = response?.data?.data;

      const uniqueFavlist = Array.from(new Set(favlist?.map(item => item.id)))
        .map(id => {
          return favlist?.find(item => item.id === id);
        });

      return uniqueFavlist;
    } catch (error) {
      console.error("Error fetching favorites:", error);
      return rejectWithValue(error.message);
    }
  }
);
export const getLogo = createAsyncThunk("cart/getLogo", async (_, thunkAPI) => {
  const { rejectWithValue,   signal } = thunkAPI;
  try {
    const response = await instance.get(`/api/logo`,   { signal });
    return response.data.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return rejectWithValue(error.message);
  }
});
