import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  quickOpen: false,
  cartOpen: false,
  item: null,
};

const ModalSlice = createSlice({
  name: "Modal",
  initialState: initialState,
  reducers: {
    openQuickModal: (state, action) => {
      state.quickOpen = true;
      state.item = action.payload;
    },
    closeQuickModal: (state) => {
      state.quickOpen = false;
      state.item = null;
    },
    openCartModal: (state, action) => {
      state.cartOpen = true;
      state.item = action.payload;
    },
    closeCartModal: (state) => {
      state.cartOpen = false;
      state.item = null;
    },
  },
});

export const {
  openQuickModal,
  closeQuickModal,
  openCartModal,
  closeCartModal,
} = ModalSlice.actions;
export default ModalSlice.reducer;
