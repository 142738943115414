import { Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Loader from "./../../component/Loader";
import {
  getContactInfo,
  formContact,
  getSocialMedia,
} from "../../store/contact/contactAction";
import { sucess, failed } from "../../component/toast/Toast";
import { useFormik } from "formik";
import { Link } from "react-router-dom";

const Contact = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const lan = useSelector((state) => state.LanSlicer.language);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      message: "",
    },
    validateOnChange: true,
    onSubmit: (values) => {
      dispatch(formContact(values)).then((result) => {
        if (!result.error) {
          sucess(t("Congrats ... we will contact you soon"));
        } else {
          failed(t("please try again later ... "));
        }
      });
    },
  });

  const { info, SocialMedia, loading } = useSelector(
    (state) => state.contactSlice
  );

  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };
  const mapinfo = stripHtmlTags(
    info
      ?.map((item) => {
        if (item?.name === "الخريطة") {
          return item?.value;
        }
        return null;
      })
      .join("")
  );

  useEffect(() => {
    dispatch(getContactInfo(lan));
    dispatch(getSocialMedia(lan));
  }, [lan, dispatch]);

  return loading === "pending" ? (
    <Loader />
  ) : (
    <Fragment>
      <div className="tt-breadcrumb">
        <div className="container">
          <ul>
            <li>
              <Link to={"/"}>{t("home")}</Link>
            </li>
            <li>/</li>
            <li>{t("contact us")}</li>
          </ul>
        </div>
      </div>
      <div className="py-5 contact">
        <div className="container-indent mb-5">
          <div className="container">
            <div className="row">
              <div className="col-12 banner mb-lg-5">
                <div>
                  <iframe
                    title="sit map"
                    className="py-4 w-100"
                    src={mapinfo}
                    width="100%"
                    height="500"
                    style={{ border: "0" }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-indent">
          <div className="container container-fluid-custom-mobile-padding">
            <div className="tt-contact02-col-list">
              <div className="row">
                <div className="col-sm-12 col-md-4 ml-sm-auto mr-sm-auto">
                  <div className="tt-contact-info">
                    <i className="fa-solid fa-phone-volume"></i>
                    <h6 className="tt-title">{t("let’s have a chat!")}</h6>
                    <address>
                      {info?.map((item) => {
                        if (item?.name === "رقم الهاتف") {
                          return <span key={item?.id}>{item?.value}</span>;
                        }
                        return null;
                      })}
                    </address>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 socials">
                  <ul className="d-flex p-0">
                    <li>
                      {SocialMedia?.data?.map((item) => {
                        if (item?.name === "الفيس بوك") {
                          return (
                            <a
                              key={item?.id}
                              aria-label="facebook"
                              target="_blank"
                              rel="noreferrer"
                              href={item?.link}
                            >
                              <i className="fab fa-facebook-f"></i>
                            </a>
                          );
                        }
                        return null;
                      })}
                    </li>
                    <li>
                      {SocialMedia?.data?.map((item) => {
                        if (item?.name === "الفيس بوك") {
                          return (
                            <a
                              key={item?.id}
                              aria-label="facebook"
                              target="_blank"
                              rel="noreferrer"
                              href={item?.link}
                            >
                              <i className="fa-brands fa-facebook-messenger"></i>
                            </a>
                          );
                        }
                        return null;
                      })}
                    </li>{" "}
                    <li>
                      {SocialMedia?.data?.map((item) => {
                        if (item?.name === "الفيس بوك") {
                          return (
                            <a
                              key={item?.id}
                              aria-label="facebook"
                              target="_blank"
                              rel="noreferrer"
                              href={item?.link}
                            >
                              <i className="fab fa-instagram"></i>
                            </a>
                          );
                        }
                        return null;
                      })}
                    </li>
                  </ul>
                </div>
                <div className="col-sm-6 col-md-4">
                  <div className="tt-contact-info">
                    <i className="fa-solid fa-location-dot"></i>
                    <h6 className="tt-title">{t("visit our location")}</h6>
                    {info.map((item) => {
                      if (item?.name === "العنوان") {
                        return <span key={item?.id}>{item?.value}</span>;
                      }
                      return null;
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-indent">
          <div className="container container-fluid-custom-mobile-padding">
            <form
              id="contactform"
              className="contact-form form-default"
              onSubmit={formik.handleSubmit}
            >
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      id="name"
                      placeholder={t("your name")}
                      value={formik.values.name}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      id="email"
                      placeholder={t("your e-mail")}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      name="phone"
                      className="form-control"
                      id="phone"
                      placeholder={t("phone")}
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <textarea
                      name="message"
                      className="form-control"
                      rows="7"
                      placeholder={t("your message")}
                      id="message"
                      value={formik.values.message}
                      onChange={formik.handleChange}
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="text-center">
                <button type="submit" className="btn main-btn">
                  {t("send message")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Contact;
