import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { baseUrl } from "../../utils/APi";
import { useTranslation } from "react-i18next";
import { gettotalOffers } from "./../../store/totaloffer/totaloffAction";
import Loader from "../../component/Loader";

const TotalOffers = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const lan = useSelector((state) => state.LanSlicer.language);
  const { offers, loading } = useSelector((state) => state.Offers);
  useEffect(() => {
    if (!offers?.length) {
     const promise =  dispatch(gettotalOffers(lan));
     return () => {
      promise.abort();
    };
    }
  }, [lan, dispatch , offers?.length]);

  return (
  
      loading === "idle" ? (
        <Fragment>
          <div className="tt-breadcrumb">
            <div className="container">
              <ul>
                <li>
                  <Link to={"/"}>{t("home")}</Link>
                </li>
                <li>/</li>
                <li>{t("offers")}</li>
              </ul>
            </div>
          </div>
          <div className="container-indent my-5">
            <div className="container">
              <div className="tt-layout-promo-box">
                <div className="row">
                  {offers?.map((item, idx) => (
                    <Link
                      to={`/shop/${item?.id}`}
                      className="col-sm-11 mx-auto my-3"
                      key={idx}
                    >
                      <div className="tt-promo-box tt-one-child">
                        <img
                          src={`${baseUrl}${item?.image}`}
                          alt={`slide${item?.id}`}
                        />
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      ) : (
        <Loader />
      )
    
  );
};

export default TotalOffers;
