import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { forgetPass, userLogin } from "./../../store/auth/authAction";
import { sucess, failed } from "../../component/toast/Toast";
const Login = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      phone: "",
      password: "",
    },
    validateOnChange: false,
    onSubmit: (values) => {
      dispatch(userLogin(values)).then((result) => {
        if (result.payload.status === "error") {
          failed(`${result.payload.message}`);
        } else {
          sucess(t("Login sucessfully"));
          navigate("/");
        }
      });
    },
  });


const [show, setShow] = useState(false);
const [email, setEmail] = useState("");


const handleResetEmail = async (e) => {
  e.preventDefault();
  if (email?.length > 0) {
    try {
      const result = await dispatch(forgetPass(email));
     
      if (result.payload.status === "success") {
        sucess(`${result.payload.message}`);
        setShow(false);
        setEmail("");
        
      } else {
        failed(t("invalid email address"));
      }
    } catch (error) {
      
    }
  } else {
    failed(t("inputs can not be empty"));
  }
};

  return (
    <Fragment>
      <div className="tt-breadcrumb">
        <div className="container">
          <ul>
            <li>
              <Link to={"/"}>{t("home")}</Link>
            </li>
            <li>/</li>
            <li>{t("login")}</li>
          </ul>
        </div>
      </div>
      <div className="container-indent py-5">
        <div className="container py-5">
          <h1 className="tt-title-subpages noborder">
            {t("already registered ?")}
          </h1>
          <div className="tt-login-form">
            <div className="row">
              <div className="col-xs-12 col-md-6">
                <div className="tt-item">
                  <h2 className="tt-title">{t("new customer")}</h2>
                  <p>
                    {t(
                      "by creating an account with our store, you will be able to move through the checkout process faster, store multiple shipping addresses, view and track your orders in your account and more."
                    )}
                  </p>
                  <div className="form-group">
                    <Link to={"/register"} className="btn btn-top main-btn">
                      {t("create an account")}
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-md-6">
                <div className="tt-item">
                  <h2 className="tt-title">{t("login")}</h2>
                  {t("if you have an account with us, please log in.")}
                  <div className="form-default form-top">
                    {show ? (
                      <form onSubmit={handleResetEmail}>
                  <input
  type="email"
  name="email"
  onChange={(e) => setEmail(e.target.value)}
  value={email}
  className="form-control"
  id="loginInputresetpass"
  placeholder={t("enter your email address")}
/>

                         <div className="col-auto mr-auto mt-2">
                          <div className="form-group">
                            <button
                              className="btn main-btn"
                              type="submit"
                              
                            >
                              {t("send")}
                            </button>
                            <button
  className="btn main-btn"
  type="button"
  onClick={() => setShow(false)}
>
  {t("Cancel")}
</button>


                          </div>
                        </div>
                        </form>):( <form id="customer_login" onSubmit={formik.handleSubmit}>
                      <div className="form-group">
                        <label htmlFor="phonelg">{t("phone")} *</label>
                        <input
                          type="number"
                          name="phone"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.phone}
                          className="form-control"
                          id="phonelg"
                          placeholder={t("enter your phone number")}
                        />
                        {formik.errors.phone && formik.touched.phone ? (
                          <p className="valid-error">{formik.errors.phone}</p>
                        ) : null}
                      </div>
                      <div className="form-group">
                        <label htmlFor="loginInputPassword">
                          {t("password")} *
                        </label>
                        <input
                          type="password"
                          name="password"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.password}
                          className="form-control"
                          id="loginInputPassword"
                          placeholder={t("enter password")}
                        />
                        {formik.errors.password && formik.touched.password ? (
                          <p className="valid-error">
                            {formik.errors.password}
                          </p>
                        ) : null}
                      </div>
                      <div className="row justify-content-between">
                        <div className="col-auto ">
                          <div className="form-group">
                            <button
                              className="btn main-btn"
                              type="submit"
                              id="submit"
                            >
                              {t("login")}
                            </button>
                          </div>
                        </div>
                        <div className="col-auto  align-self-center">
                          <div className="form-group">
                            <ul className="additional-links">
                              <li>
                                <button className="lostpass" onClick={()=>setShow(true)} >{t("lost your password ?")}</button>
                              </li>
                            </ul>
                          </div>
                        </div> 
                      </div>
                    </form>)}
                      </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default Login;
