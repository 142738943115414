import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { failed, sucess } from "../../component/toast/Toast";
import { addJob } from "../../store/contact/contactAction";

const JobForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
    file: null,
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: files ? files[0] : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const result = await dispatch(addJob(formData));
     
      if (result?.payload?.status === "error") {
        failed(`${result?.payload?.message}`);
      } else {
        sucess(t("Congrats ... we will contact you soon"));
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Fragment>
      <div className="tt-breadcrumb">
        <div className="container my-4">
          <ul>
            <li>
              <Link to={"/"}>{t("home")}</Link>
            </li>
            <li>/</li>
            <li>{t("Job Form")}</li>
          </ul>
        </div>
      </div>

      <div className="container container-fluid-custom-mobile-padding ">
        <h1 className="tt-title-subpages noborder mt-3">{t("Job Form")}</h1>
        <div className="account-container mb-2 mx-auto">
          <div className="tt-shopping-layout">
            <div className="tt-wrapper">
              <div className="form-default">
                <form
                  id="contactform"
                  className="contact-form form-default"
                  onSubmit={handleSubmit}
                  enctype="multipart/form-data"
                >
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          name="name"
                          className="form-control"
                          id="name"
                          placeholder={t("your name")}
                          value={formData.name}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          id="email"
                          placeholder={t("your e-mail")}
                          value={formData.email}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          name="phone"
                          className="form-control"
                          id="phone"
                          placeholder={t("phone")}
                          value={formData.phone}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="file"
                          name="file"
                          className="form-control"
                          id="file"
                          placeholder={t("document")}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <textarea
                          name="message"
                          className="form-control"
                          rows="7"
                          placeholder={t("your message")}
                          id="message"
                          value={formData.message}
                          onChange={handleChange}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="text-center">
                    <button type="submit" className="btn main-btn">
                      {t("send message")}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default JobForm;
